import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { getArrayIdFromObject } from '../../js/common';

const DaySelector = ({ selectedDay, onChange }) => {
  const options = [
    { value: 'Monday', label: 'Monday' },
    // { value: 'Tuesday', label: 'Tuesday' },
    // { value: 'Wednesday', label: 'Wednesday' },
    // { value: 'Thursday', label: 'Thursday' },
    // { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' },
  ];

  return (
    <Select
      value={selectedDay}
      onChange={onChange}
      options={options}
      placeholder="Select a day"
      menuPortalTarget={document.body}      
    />
  );
};

DaySelector.propTypes = {
  selectedDay: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default DaySelector;
