import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import Form from 'react-bootstrap/Form';
import InfoPopover from '../common/InfoPopover';
import { Col, Row } from 'react-bootstrap';
import { faHouseChimneyMedical, faTrashAlt, faCircleInfo} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CLINICAL_BLACKOUTS } from '../../config';
import moment from 'moment';
import { isDateInNext6Months, isEmpty } from '../../js/common';

const PatientBlackoutForm = ({patientBlackouts, setPatientBlackouts, day1StartDate, calendarMode}) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    // initialize the radio buttons using the list of blackouts
    const [patientIsTravelling, setPatientIsTravelling] = useState(patientBlackouts ? !isEmpty(patientBlackouts) : undefined );

    const onChange = (dates) => {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);
        if (start && end) {
            const newRange = { start: new Date(start), end: new Date(end), type: 'patient', title: '' };
            const newPatientBlackouts = isEmpty(patientBlackouts) ? [newRange] : [...patientBlackouts, newRange];
            setPatientBlackouts(newPatientBlackouts);
            setStartDate(null);
            setEndDate(null);
        }
      };
        
    const removeDateRange = (index) => {
      const updatedRanges = [...patientBlackouts];
      updatedRanges.splice(index, 1);
      setPatientBlackouts(updatedRanges);
    };
    const onTravelSelect = (e) => {
        setPatientIsTravelling(e.target.value === 'true')
        if (e.target.value === 'false'){
            setPatientBlackouts([]);
        }
    }
    
    return (
        <Form className='patient-blackout-form-container'>
            <Form.Group className="mb-3 w-100" controlId="formTravelling">
            <Row className="day-1-input-group">
              <Col sm="12" md="12" lg="8" >
                <Form.Label className='required'>
                    { calendarMode=== 'patient' ?
                     <>Do you have any commitments in the 2 months after your expected period start? </>:
                     <>Does patient have any commitments within the next 2 months (if applicable) </>
                    }
                    
                    &nbsp;&nbsp;
                    <InfoPopover 
                        position={'top'} 
                        body={
                        <p> Comittments can include planned travel, or any appointment/event that would prevent you from being able to come into the clinic for a scheduled cycle monitoring appointment.
                            <div className='text-center'>
                                <br/>

                                {CLINICAL_BLACKOUTS.map((range) => {
                                    const startDate = moment(range.start);
                                    const endDate = moment(range.end);
                                    if (isDateInNext6Months(startDate) || isDateInNext6Months(endDate)){
                                        const typeOfBlackout = 'Clinic Closure';
                                        const icon = <FontAwesomeIcon icon={faHouseChimneyMedical} size="xl"/>;
                                        return(                
                                            <div>{icon}&nbsp;<b>{typeOfBlackout}</b>: {moment(range.start).format('MMM D, YYYY')} - {moment(range.end).format('MMM D, YYYY')}</div>
                                        )
                                    }
                                    else return;
                                }
                                )}
                            </div>

                        </p>} 
                    > 
                    <span className='clickable-container'><FontAwesomeIcon icon={faCircleInfo}/> </span>
                    </InfoPopover> 
                </Form.Label>
                </Col>
                <Col sm="12" md="12" lg="4" >
                    <div className='inline-radio'>
                        <Form.Check
                            checked={patientIsTravelling===true}
                            type="radio"
                            label="Yes"
                            value={true}
                            name="radioGroup" 
                            id="radioOption1"
                            onChange={onTravelSelect}
                            />
                        <Form.Check
                            checked={patientIsTravelling===false}
                            type="radio"
                            label="No"
                            value={false}
                            name="radioGroup" 
                            id="radioOption2"
                            onChange={onTravelSelect}
                            // defaultChecked
                        />
                    </div>
                </Col>
            </Row>
            </Form.Group>
        {patientIsTravelling === true && 
            <Form.Group className="travel-dates-group w-100" controlId="formTravelling">
                <Form.Label className="travel-dates-label">Please specify any date ranges where you will not be able to visit the clinic. <br/> <i>You may select multiple date ranges:</i> </Form.Label>
                <br/>
                <DatePicker
                    placeholderText="Select Date Range"
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    minDate={day1StartDate|| null}
                    portalId="root"
                      />
                    <div>
                        <ul>
                            {patientBlackouts && patientBlackouts.length > 0 && 
                                <div className="mt-2 selected-away-dates">
                                    <h6>Selected Date Ranges</h6>
                                    {patientBlackouts.map((range, index) => (
                                        <li key={index}>
                                            {new Date(range.start).toDateString() === new Date(range.end).toDateString() ?
                                                 new Date(range.start).toDateString() : 
                                                 `${new Date(range.start).toDateString()} - ${new Date(range.end).toDateString()}`
                                                
                                            }
                                            <span className={'clickable-container'} onClick={() => removeDateRange(index)}>&nbsp;<FontAwesomeIcon className='delete-icon' icon={faTrashAlt}/></span>
                                        </li>
                                    ))}
                                </div>
                            }
                        </ul>
                    </div>
            </Form.Group>
        }
        </Form>
    );
  };
  export default PatientBlackoutForm;
