import { faGripVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Draggable from 'react-draggable';

const DraggablePopup = ({title, children}) => {
  return (
    <Draggable handle=".draggable-header">
      <div className='draggable-container'>
        <div className="draggable-header">
            <FontAwesomeIcon icon={faGripVertical}/> &nbsp;
            {title}
        </div>
        <div class="content">
            {children}
        </div>
      </div>
    </Draggable>
  );
};

export default DraggablePopup;
