import getPergoverisDose from "./temp_pergoveris";
import { isEmpty } from "./common";

import moment from 'moment';
import { extendMoment } from 'moment-range';
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import MedicationList from "../Components/Calendar/MedicationList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const momentRange = extendMoment(moment);

const medsForDayAndNight = (dayMeds, nightMeds, extraInfo) => ({
  dayMeds: dayMeds.length ? <MedicationList meds={dayMeds} icon={<FontAwesomeIcon icon={faSun} size={'md'} />} /> : '',
  nightMeds: nightMeds.length ?  <MedicationList meds={nightMeds} icon={<FontAwesomeIcon icon={faMoon} size={'md'} /> } /> : '',
  extraInfo: extraInfo ? <span>{extraInfo}</span> : ''
});

// 7-15-2024 : we introduce sorting by date, so that dynamic appointment dates can appear in the correct spot
const sortItemsByDate = items => {
  return items
      .filter(item => item.sortDate !== null)
      .sort((a, b) => a.sortDate.diff(b.sortDate));
};

// 7-15-2024 : we introduce sorting by date, so that dynamic appointment dates can appear in the correct spot
const generateDateItem = (date, content, format = "MMMM Do") => {
  const displayDate = date.format(format);
  return { sortDate: date, date: displayDate, content };
};

export const getPhase1Meds = (event, dayOfEvent, amh, weight, momentStartDate, momentEndDate, dayOfEventMoment) => {
  const order = dayOfEvent.extendedProps.order;
  const totalNumberDays = momentEndDate.diff(momentStartDate.clone().subtract(1, 'days'), 'days'); // full calendar end dates are exclusive, so reduce them by 1 for moment to accurately get the last week
  const isLastDay = order === totalNumberDays-1;
  const isEighthToLastDay = order === totalNumberDays-8;
  const isWithinLast8Days = totalNumberDays - order <= 8;

  if (isLastDay) {
    return {
      dayMeds: <MedicationList meds={[{ med: 'Lupin-Estradiol', medicationClassName: 'mark' }, { med: 'Prometrium', medicationClassName: 'mark' }]} icon={<FontAwesomeIcon icon={faSun} size={'md'} key={momentStartDate+'-day'} />}/> ,
      nightMeds: <MedicationList meds={[{ med: 'Lupin-Estradiol', medicationClassName: 'mark' }, { med: 'Prometrium', medicationClassName: 'mark' }]} icon={<FontAwesomeIcon icon={faMoon} size={'md'} key={momentStartDate+'-night'} />}/>,
    }
  } else if (isEighthToLastDay) {
    return {
      dayMeds: <MedicationList meds={[{ med: 'Lupin-Estradiol' }, { med: 'Prometrium', medicationClassName: 'mark' }]} icon={<FontAwesomeIcon icon={faSun} size={'md'} key={momentStartDate+'-day'} />}/> ,
      nightMeds: <MedicationList meds={[{ med: 'Lupin-Estradiol' }, { med: 'Prometrium', medicationClassName: 'mark' }]} icon={<FontAwesomeIcon icon={faMoon} size={'md'} key={momentStartDate+'-night'} />}/>,
    }
  } else if (isWithinLast8Days) {
    return {
      dayMeds: <MedicationList meds={[{ med: 'Lupin-Estradiol' }, { med: 'Prometrium' }]} icon={<FontAwesomeIcon icon={faSun} size={'md'} key={momentStartDate+'-day'} />}/> ,
      nightMeds: <MedicationList meds={[{ med: 'Lupin-Estradiol' }, { med: 'Prometrium' }]} icon={<FontAwesomeIcon icon={faMoon} size={'md'} key={momentStartDate+'-night'} />}/>,
    }
  } else {
    return {
      dayMeds: order === 1 ? '' : <MedicationList meds={[{ med: 'Lupin-Estradiol' }]} icon={<FontAwesomeIcon icon={faSun} size={'md'} key={momentStartDate+'-day'} />}/>, // on first day, only take 1 in PM
      nightMeds: <MedicationList meds={[{ med: 'Lupin-Estradiol' }]} icon={<FontAwesomeIcon icon={faMoon} size={'md'} key={momentStartDate+'-night'} />}/>,
    }
  }
}
  
export async function getPhase1Desc(event, amh, weight, cycleOrientation, monitoringAppointments) {
  const startDate = moment(event.start).clone();
  const endDate = moment(event.end).clone();
  
  const day1 = startDate.clone();
  const day2 = startDate.clone().add(1, 'days');
  const eighthBeforeLastDay = endDate.clone().subtract(8, 'days');
  const endDateFormatted = endDate.clone().subtract(1, 'days');
  const cycleOrientationDate = !isEmpty(cycleOrientation) ? moment(cycleOrientation.start).clone() : null;

  const items = [
      generateDateItem(day1, 'You will be instructed to start Lupin-Estradiol 2mg (1 tablet) inserted vaginally in the evening.'),
      generateDateItem(day2, 'Increase dose of Lupin-Estradiol 2mg (1 tablet) vaginally to TWICE daily (morning and evening) for a total of 2 tablets/day. Continue taking this medication daily with no changes, as outlined in calendar.'),
      generateDateItem(eighthBeforeLastDay, (
          <>
              Add Prometrium 200mg (2 tablets) vaginally twice daily (morning and evening) for a total of 4 tablets/day. <br />
              <b><mark>We recommend you set a reminder for this.</mark></b>
          </>
      )),
      generateDateItem(endDateFormatted, 'Take last dose of Lupin-Estradiol and Prometrium as scheduled in evening. This is the last day of pre-treatment medications.'),
  ];
  if (!isEmpty(cycleOrientationDate)){
    items.push(generateDateItem(cycleOrientationDate, 'Log in to zoom for your cycle orientation appointment using the link emailed by your nurse.'))
  }

  const sortedItems = sortItemsByDate(items);

  return (
      <ul>
          {sortedItems.map((item, index) => (
              <li key={index}><b>{item.date}: </b>{item.content}</li>
          ))}
          <li>Refer to medication PDF for more information on these medications, including side effects</li>
      </ul>
  );
}

export const getPhase2Meds = (event, dayOfEvent, amh, weight, momentStartDate, momentEndDate, dayOfEventMoment, pergoverisDose) => {
  const order = dayOfEvent.extendedProps.order;

  if (amh < 12) {
    switch (order) {
      case 1:
        return medsForDayAndNight([], [
          { med: 'Dexamethasone' },
          { med: 'Clomiphene' },
          { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
        ]);
      case 2:
        return medsForDayAndNight(
          [{ med: 'Eltroxin', medicationClassName: 'bold' }],
          [
            { med: 'Dexamethasone' },
            { med: 'Clomiphene' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      case 3:
      case 4:
      case 5:
      case 6:
        return medsForDayAndNight(
          [{ med: 'Eltroxin' }],
          [
            { med: 'Dexamethasone' },
            { med: 'Clomiphene' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      case 7:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: '(250mcg)', medicationClassName: 'bold mark' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Clomiphene' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      case 8:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: '(250mcg)', medicationClassName: 'bold' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Clomiphene' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      default:
        break;
    }
  } else if (amh >= 12 && amh < 24) {
    switch (order) {
      case 1:
        return medsForDayAndNight([], [
          { med: 'Dexamethasone' },
          { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
        ]);
      case 2:
        return medsForDayAndNight(
          [{ med: 'Eltroxin', medicationClassName: 'bold' }],
          [
            { med: 'Dexamethasone' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      case 3:
      case 4:
        return medsForDayAndNight(
          [{ med: 'Eltroxin' }],
          [
            { med: 'Dexamethasone' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      case 5:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: '(125mcg)', medicationClassName: 'bold mark' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      case 6:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: '(dosage TBD)', medicationClassName: 'bold' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      case 7:
      case 8:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: '(250mcg)', medicationClassName: 'bold' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      default:
        break;
    }
  } else if (amh >= 24) {
    switch (order) {
      case 1:
        return medsForDayAndNight([], [
          { med: 'Dexamethasone' },
          { med: 'Metformin' },
          { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
        ]);
      case 2:
        return medsForDayAndNight(
          [{ med: 'Eltroxin', medicationClassName: 'bold' }],
          [
            { med: 'Dexamethasone' },
            { med: 'Metformin' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      case 3:
      case 4:
        return medsForDayAndNight(
          [{ med: 'Eltroxin' }],
          [
            { med: 'Dexamethasone' },
            { med: 'Metformin' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      case 5:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: '(125mcg)', medicationClassName: 'bold mark' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Metformin' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      case 6:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: '(dosage TBD)', medicationClassName: 'bold' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Metformin' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      case 7:
      case 8:
        return medsForDayAndNight(
          [
            { med: 'Orgalutran', dosage: '(250mcg)', medicationClassName: 'bold' },
            { med: 'Eltroxin' }
          ],
          [
            { med: 'Dexamethasone' },
            { med: 'Metformin' },
            { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
          ]
        );
      default:
        break;
    }
  }
};

  export async function getPhase2Desc(event, amh, weight, monitoringAppointments=[]) {
    const pergoverisDose = <span dangerouslySetInnerHTML={{ __html: await getPergoverisDose(amh, weight) }}></span>;
    const startDate = moment(event.start).clone();
  
    const formatDay = (dayOffset) => startDate.clone().add(dayOffset, 'days').format('MMMM Do');
    const monitoringDates = monitoringAppointments.map(app => moment(app.start).format('MMMM Do'));
  
    const day1 = formatDay(0);
    const day2 = formatDay(1);
    const day3 = formatDay(2);
    const day4 = formatDay(3);
    const day5 = formatDay(4);
    const day6 = formatDay(5);
    const day7 = formatDay(6);
    const day8 = formatDay(7);
    
    const shouldShowMonitoring = (day, text='Come to clinic for monitoring as scheduled. Pending call from nurse:') => monitoringDates.includes(day) ? <u>{text}</u> : null;
  
    if (amh < 12) {
      return (
        <ul>
          <li><b>{day1}</b>: {shouldShowMonitoring(day1, 'Come to clinic for monitoring as scheduled.')} At the same time in the evening (PM), start: Dexamethasone 0.5mg orally once daily, Clomiphene 100mg orally once daily, and Pergoveris {pergoverisDose} injected into belly.</li>
          <li><b>{day2}</b>: At the same time in the morning (AM), start Eltroxin (Synthroid) 50mcg orally once daily. {shouldShowMonitoring(day2)} In the evening (PM), continue Dexamethasone 0.5mg, Clomiphene 100mg, and Pergoveris {pergoverisDose}.</li>
          <li><b>{day3}</b>: In the morning (AM), continue Eltroxin 50mcg. {shouldShowMonitoring(day3)} In the evening (PM), continue Dexamethasone 0.5mg, Clomiphene 100mg, and Pergoveris {pergoverisDose}.</li>
          <li><b>{day4}</b>: In the morning (AM), continue Eltroxin 50mcg. {shouldShowMonitoring(day4)} In the evening (PM), continue Dexamethasone 0.5mg, Clomiphene 100mg, and Pergoveris {pergoverisDose}.</li>
          <li><b>{day5}</b>: In the morning (AM), continue Eltroxin 50mcg. {shouldShowMonitoring(day5)} In the evening (PM), continue Dexamethasone 0.5mg, Clomiphene 100mg, and Pergoveris {pergoverisDose}.</li>
          <li><b>{day6}</b>: In the morning (AM), continue Eltroxin 50mcg. {shouldShowMonitoring(day6)} In the evening (PM), continue Dexamethasone 0.5mg, Clomiphene 100mg, and Pergoveris {pergoverisDose}.</li>
          <li><b>{day7}</b>: At the same time in the morning (AM), start Orgalutran 250mcg (full dose), and inject into belly. In the morning (AM), continue Eltroxin 50mcg. {shouldShowMonitoring(day7)} In the evening (PM), continue Dexamethasone 0.5mg, Clomiphene 100mg, and Pergoveris {pergoverisDose}.</li>
          <li><b>{day8}</b>: At the same time in the morning (AM), continue Orgalutran 250mcg (full dose), and inject into belly before monitoring. In the morning (AM), continue Eltroxin 50mcg. {shouldShowMonitoring(day8)} In the evening (PM), continue Dexamethasone 0.5mg, Clomiphene 100mg, and Pergoveris {pergoverisDose}.</li>
        </ul>
      );
    } else if (amh >= 12 && amh < 24) {
      return (
        <ul>
          <li><b>{day1}</b>: {shouldShowMonitoring(day1, 'Come to clinic for monitoring as scheduled.')} At the same time in the evening, start: Dexamethasone 0.5mg orally once daily, and Pergoveris {pergoverisDose} injected into belly.</li>
          <li><b>{day2}</b>: At the same time in the morning (AM), start Eltroxin (Synthroid) 50mcg orally once daily. {shouldShowMonitoring(day2)} In the evening (PM), continue taking Dexamethasone 0.5mg and Pergoveris {pergoverisDose}.</li>
          <li><b>{day3}</b>: In the morning (AM), continue Eltroxin 50mcg. {shouldShowMonitoring(day3)} In the evening (PM), continue Dexamethasone 0.5mg and Pergoveris {pergoverisDose}.</li>
          <li><b>{day4}</b>: In the morning (AM), continue Eltroxin 50mcg. {shouldShowMonitoring(day4)} In the evening (PM), continue Dexamethasone 0.5mg and Pergoveris {pergoverisDose}.</li>
          <li><b>{day5}</b>: At the same time in the morning (AM), start Orgalutran 125mcg (half dose), and inject into belly before monitoring. In the morning (AM), continue Eltroxin 50mcg. {shouldShowMonitoring(day5)} In the evening (PM), continue Dexamethasone 0.5mg and Pergoveris {pergoverisDose}.</li>
          <li><b>{day6}</b>: At the same time in the morning (AM), continue Orgalutran (dose TBD), and Eltroxin 50mcg. {shouldShowMonitoring(day6)} In the evening (PM), continue Dexamethasone 0.5mg and Pergoveris {pergoverisDose}.</li>
          <li><b>{day7}</b>: At the same time in the morning (AM), continue Orgalutran (dose TBD), and Eltroxin 50mcg. {shouldShowMonitoring(day7)} In the evening (PM), continue Dexamethasone 0.5mg and Pergoveris {pergoverisDose}.</li>
          <li><b>{day8}</b>: At the same time in the morning (AM), continue Orgalutran 250mcg and Eltroxin 50mcg. {shouldShowMonitoring(day8)} In the evening (PM), continue Dexamethasone 0.5mg and Pergoveris {pergoverisDose}.</li>
        </ul>
      );
    } else if (amh >= 24) {
      return (
        <ul>
          <li><b>{day1}</b>: {shouldShowMonitoring(day1, 'Come to clinic for monitoring as scheduled.')} At the same time in the evening, start: Dexamethasone 0.5mg orally once daily, Metformin 500mg orally once daily, and Pergoveris {pergoverisDose} injected into belly.</li>
          <li><b>{day2}</b>: At the same time in the morning (AM), start Eltroxin (Synthroid) 50mcg orally once daily. {shouldShowMonitoring(day2)} In the evening (PM), continue taking Dexamethasone 0.5mg, Metformin 500mg, and Pergoveris {pergoverisDose}.</li>
          <li><b>{day3}</b>: In the morning (AM), continue Eltroxin 50mcg. {shouldShowMonitoring(day3)} In the evening (PM), continue Dexamethasone 0.5mg, Metformin 500mg, and Pergoveris {pergoverisDose}.</li>
          <li><b>{day4}</b>: In the morning (AM), continue Eltroxin 50mcg. {shouldShowMonitoring(day4)} In the evening (PM), continue Dexamethasone 0.5mg, Metformin 500mg, and Pergoveris {pergoverisDose}.</li>
          <li><b>{day5}</b>: At the same time in the morning (AM), start Orgalutran 125mcg (half dose), and inject into belly before monitoring. In the morning (AM), continue Eltroxin 50mcg. {shouldShowMonitoring(day5)} In the evening (PM), continue Dexamethasone 0.5mg, Metformin 500mg, and Pergoveris {pergoverisDose}.</li>
          <li><b>{day6}</b>: At the same time in the morning (AM), continue Orgalutran (dose TBD), and Eltroxin 50mcg. {shouldShowMonitoring(day6)} In the evening (PM), continue Dexamethasone 0.5mg, Metformin 500mg, and Pergoveris {pergoverisDose}.</li>
          <li><b>{day7}</b>: At the same time in the morning (AM), continue Orgalutran (dose TBD), and Eltroxin 50mcg. {shouldShowMonitoring(day7)} In the evening (PM), continue Dexamethasone 0.5mg, Metformin 500mg, and Pergoveris {pergoverisDose}.</li>
          <li><b>{day8}</b>: At the same time in the morning (AM), continue Orgalutran 250mcg and Eltroxin 50mcg. {shouldShowMonitoring(day8)} In the evening (PM), continue Dexamethasone 0.5mg, Metformin 500mg, and Pergoveris {pergoverisDose}.</li>
        </ul>
      );
    }
  }
  
  
  export const getPhase3Meds = (event, dayOfEvent, amh, weight, momentStartDate, momentEndDate, dayOfEventMoment, takingAntibiotics, pergoverisDose) => {
    const order = dayOfEvent.extendedProps.order;
    
    if (amh < 12) {
      switch (order) {
        case 1:
          return medsForDayAndNight(
            [
              { med: 'Orgalutran', dosage: '(250mcg)', medicationClassName: 'bold' },
              { med: 'Eltroxin' }
            ],
            [
              { med: 'Dexamethasone' },
              { med: 'Clomiphene' },
              { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
            ]
          );
        case 2:
          return medsForDayAndNight(
            [
              { med: 'Orgalutran', dosage: '(250mcg)', medicationClassName: 'bold' },
              { med: 'Eltroxin' }
            ],
            [
              { med: 'Dexamethasone' },
              { med: 'Clomiphene' },
              { med: 'Pergoveris', dosage: `(${pergoverisDose})` },
              { med: 'If Triggered: Ovidrel+Decapeptyl', medicationClassName: 'bold mark' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav', medicationClassName: 'bold' }] : [])
            ]
          );
        case 3:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            'Post-trigger bloodwork'
          );
        case 4:
        case 5:
        case 6:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Letrozole' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ]
          );
        default:
          break;
      }
    } else if (amh >= 12 && amh < 24) {
      switch (order) {
        case 1:
          return medsForDayAndNight(
            [
              { med: 'Orgalutran', dosage: '(250mcg)', medicationClassName: 'bold' },
              { med: 'Eltroxin' }
            ],
            [
              { med: 'Dexamethasone' },
              { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
            ]
          );
        case 2:
          return medsForDayAndNight(
            [
              { med: 'Orgalutran', dosage: '(250mcg)', medicationClassName: 'bold' },
              { med: 'Eltroxin' }
            ],
            [
              { med: 'Dexamethasone' },
              { med: 'If Triggered: Ovidrel+Decapeptyl', medicationClassName: 'bold mark' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav', medicationClassName: 'bold' }] : [])
            ]
          );
        case 3:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            'Post-trigger bloodwork'
          );
        case 4:
        case 5:
        case 6:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Letrozole' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ]
          );
        default:
          break;
      }
    } else if (amh >= 24) {
      switch (order) {
        case 1:
          return medsForDayAndNight(
            [
              { med: 'Orgalutran', dosage: '(250mcg)', medicationClassName: 'bold' },
              { med: 'Eltroxin' }
            ],
            [
              { med: 'Dexamethasone' },
              { med: 'Metformin' },
              { med: 'Pergoveris', dosage: `(${pergoverisDose})` }
            ]
          );
        case 2:
          return medsForDayAndNight(
            [
              { med: 'Orgalutran', dosage: '(250mcg)', medicationClassName: 'bold' },
              { med: 'Eltroxin' }
            ],
            [
              { med: 'Dexamethasone' },
              { med: 'Metformin' },
              { med: 'If Triggered: Ovidrel+Decapeptyl', medicationClassName: 'bold mark' },
              { med: 'Cabergoline' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav', medicationClassName: 'bold' }] : [])
            ]
          );
        case 3:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Cabergoline' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ],
            'Post-trigger bloodwork'
          );
        case 4:
        case 5:
        case 6:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Cabergoline' },
              { med: 'Letrozole' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ]
          );
        default:
          break;
      }
    }
  };
    
  export async function getPhase3Desc (event, amh=26, weight, takingAntibiotics=false, monitoringAppointments=[]) {
    const pergoverisDose = <span dangerouslySetInnerHTML={{__html:  await getPergoverisDose(amh, weight)}}></span>

    const startDate = moment(event.start).clone();
    const endDate = moment(event.end).clone();

    const formatDay = (dayOffset) => startDate.clone().add(dayOffset, 'days').format('MMMM Do');
    const monitoringDates = monitoringAppointments.map(app => moment(app.start).format('MMMM Do'));

    const day1 = formatDay(0);
    const day2 = formatDay(1);
    const day3 = formatDay(2);
    const day4 = formatDay(3);
    const day5 = formatDay(4);
    const day6 = formatDay(5);
    const day7 = formatDay(6);
    const day8 = formatDay(7);
    const shouldShowMonitoring = (day, text='Come to clinic for monitoring as scheduled. Pending call from nurse:') => monitoringDates.includes(day) ? <u>{text}</u> : null;

    if (amh < 12){
        return <ul>
            <li> <b>{day1}: </b> At same time in morning (AM), continue Orgalutran 250mcg and Eltroxin 50mcg. {shouldShowMonitoring(day1)} In evening (PM), continue Dexamethasone 0.5mg, Clomiphene 100mg, and Pergoveris {pergoverisDose}. This is earliest possible trigger date.</li>
            <li> <b> {day2} (if triggered): </b> At same time in morning (AM), continue Orgalutran 250mcg and Eltroxin 50mcg. <u>Come to clinic for monitoring as scheduled.</u> Pending call from nurse, if triggered, you will receive specific instructions for ovulation trigger. This includes: In evening (PM), continue Dexamethasone 0.5mg, and Clomiphene 100mg. Inject Ovidrel into belly 35 hours before egg retrieval, and Decapeptyl 0.1mg.mL x 2 syringes injected into belly 34 hours before egg retrieval. </li>
            {takingAntibiotics === true && <li> On the evening of your trigger, take 1 Amoxi-clav 875/125mg tablet orally. Continue taking 1 Amoxi-Clavulanate in the AM and PM for the next 6 days </li>}
            <li> <b> {day3} (if triggered on {day2}): </b> <u>You will be scheduled for blood work only this morning at the clinic.</u> You will not receive a call from nurse on this day. </li>
            <li><b> {day4} (if triggered on {day2}): </b>  <u>You will be scheduled for egg retrieval this morning at the clinic.</u> In evening (PM), start Letrozole 10mg (4 x 2.5mg tablets all together at once) for 5 days in a row.</li>
        </ul>
      } else if (amh >= 12 && amh < 24) {
        return <ul>
            <li> <b>{day1}: </b> At same time in morning (AM), continue Orgalutran 250mcg and Eltroxin 50mcg. {shouldShowMonitoring(day1)} In evening (PM), continue Dexamethasone 0.5mg and Pergoveris {pergoverisDose}. This is earliest possible trigger date.</li>
            <li> <b> {day2} (if triggered): </b> At same time in morning (AM), continue Orgalutran 250mcg and Eltroxin 50mcg. <u>Come to clinic for monitoring as scheduled.</u> Pending call from nurse, if triggered, you will receive specific instructions for ovulation trigger. This includes: In evening (PM), continue Dexamethasone 0.5mg. Inject Ovidrel into belly 35 hours before egg retrieval, and Decapeptyl 0.1mg.mL x 2 syringes injected into belly 34 hours before egg retrieval. </li>
            {takingAntibiotics === true && <li> On the evening of your trigger, take 1 Amoxi-clav 875/125mg tablet orally. Continue taking 1 Amoxi-Clavulanate in the AM and PM for the next 6 days </li>}
            <li> <b> {day3} (if triggered on {day2}): </b> <u>You will be scheduled for blood work only this morning at the clinic.</u> You will not receive a call from nurse on this day. </li>
            <li><b> {day4} (if triggered on {day2}): </b>   <u>You will be scheduled for egg retrieval this morning at the clinic.</u> In evening (PM), start Letrozole 10mg (4 x 2.5mg tablets all together at once) for 5 days in a row.</li>
        </ul>
    } else if (amh >= 24){
        return <ul>
            <li> <b>{day1}: </b> At same time in morning (AM), continue Orgalutran 250mcg and Eltroxin 50mcg. {shouldShowMonitoring(day1)} In evening (PM), continue Dexamethasone 0.5mg, Metformin 500mg, and Pergoveris {pergoverisDose}. This is earliest possible trigger date.</li>
            <li> <b> {day2} (if triggered): </b> At same time in morning (AM), continue Orgalutran 250mcg and Eltroxin 50mcg. <u>Come to clinic for monitoring as scheduled.</u> Pending call from nurse, if triggered, you will receive specific instructions for ovulation trigger. This includes: In evening (PM), continue Dexamethasone 0.5mg, and Metformin 500mg. Start Cabergoline 0.5mg orally for 7 days in a row, along with Ovidrel injected into belly 35 hours before egg retrieval, and Decapeptyl 0.1mg.mL x 2 syringes injected into belly 34 hours before egg retrieval. </li>
            {takingAntibiotics === true && <li> On the evening of your trigger, take 1 Amoxi-clav 875/125mg tablet orally. Continue taking 1 Amoxi-Clavulanate in the AM and PM for the next 6 days </li>}
            <li> <b> {day3} (if triggered on {day2}): </b> <u>You will be scheduled for blood work only this morning at the clinic.</u> You will not receive a call from nurse on this day. In evening (PM), continue Cabergoline 0.5mg daily. </li>
            <li> <b> {day4} (if triggered on {day2}): </b> <u>You will be scheduled for egg retrieval this morning at the clinic.</u> In evening (PM), continue  Cabergoline 0.5mg daily, and start Letrozole 10mg (4 x 2.5mg tablets all together at once) for 5 days in a row.</li>
        </ul>
    }
  }


  export const getPhase4Meds = (event, dayOfEvent, amh, weight, momentStartDate, momentEndDate, dayOfEventMoment, takingAntibiotics) => {
    const order = dayOfEvent.extendedProps.order;
    
    if (amh < 12) {
      switch (order) {
        case 1:
        case 2:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Letrozole' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ]
          );
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        default:
          break;
      }
    } else if (amh >= 12 && amh < 24) {
      switch (order) {
        case 1:
        case 2:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Letrozole' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ]
          );
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        default:
          break;
      }
    } else if (amh >= 24) {
      switch (order) {
        case 1:
        case 2:
          return medsForDayAndNight(
            takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [],
            [
              { med: 'Cabergoline' },
              { med: 'Letrozole' },
              ...(takingAntibiotics ? [{ med: 'Amoxi-clav' }] : [])
            ]
          );
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
        default:
          break;
      }
    }
  };
    

  export async function getPhase4Desc  (event, amh, weight, momentStartDate, momentEndDate , monitoringAppointments) {
    const startDate = moment(event.start).clone();
    const endDate = moment(event.end).clone();

    const day1to2 = startDate.clone().format("MMMM Do") + " to " + startDate.clone().add(1, 'days').format("MMMM Do");
    const dayMinus2to2 = startDate.clone().subtract(2, 'days').format("MMMM Do") + " to " + startDate.clone().add(1, 'days').format("MMMM Do")
    const estimatedTrigger = startDate.clone().subtract(5, 'days').format("MMMM Do")
    const startToEndDateFormatted = startDate.clone().format("MMMM Do") + " to " + endDate.clone().subtract(1, 'days').format("MMMM Do");

    if (amh < 12){
     return <ul>
        <li><b>{dayMinus2to2} (if triggered on {estimatedTrigger}): </b>Continue taking Letrozole 10mg (4 x 2.5mg tablets all together at once) following the egg retrieval procedure. The medication should be taken until it's done.</li>
        <li><b>{startToEndDateFormatted}: </b>Please stay close to the clinic in the event you need to be seen in person in the week following your egg retrieval procedure.</li>
      </ul>
    } else if (amh >= 12 && amh < 24) {
      return <ul>
        <li><b>{dayMinus2to2} (if triggered on {estimatedTrigger}): </b>Continue taking Letrozole 10mg (4 x 2.5mg tablets all together at once) following the egg retrieval procedure. The medication should be taken until it's done.</li>
        <li><b>{startToEndDateFormatted}: </b>Please stay close to the clinic in the event you need to be seen in person in the week following your egg retrieval procedure.</li>
      </ul>
    } else if (amh >= 24){
      return <ul>
        <li><b>{dayMinus2to2} (if triggered on {estimatedTrigger}): </b>Continue both Cabergoline 0.5mg daily, and Letrozole 10mg orally (4 x 2.5mg tablets all together at once) following the egg retrieval procedure. Both medications should be taken until they're done.</li>
        <li><b>{startToEndDateFormatted}: </b>Please stay close to the clinic in the event  you need to be seen in person in the week following your egg retrieval procedure.</li>
      </ul>
    } 

}

function getMonitoringApptMatches(noMedsEvent, monitoringAppointments, stimStartDay) {

  const noMedsStart = moment(noMedsEvent.start);
  const noMedsEnd = moment(noMedsEvent.end).clone().subtract('days', 1);
  const noMedsRange = momentRange.range(noMedsStart, noMedsEnd);

  const overlappingAppointments = monitoringAppointments.filter(appointment => {
    const appointmentStart = moment(appointment.start);
    return noMedsRange.contains(appointmentStart);
  });

  
  return overlappingAppointments.map(appointment => (
    <li key={appointment.start}>
      <b>{moment(appointment.start).clone().format("MMMM Do")}</b>: <u>Come to clinic for monitoring as scheduled. This assessment is to clear you to start on {stimStartDay.stimStartDayOfWeek}</u>
    </li>
  ));
}

export async function getNoMedsDesc(event, amh, weight, monitoringAppointments, stimStartDay) {

  return (
    <ul>
      {getMonitoringApptMatches(event, monitoringAppointments, stimStartDay)}
    </ul>
  );
}
