import React, { useState   } from 'react';
import { Dna } from 'react-loader-spinner';

const LoadingSpinner = () => {

  return (
      <div className='spinner-wrapper'>
        <Dna
          visible={true}
          height="80"
          width="80"
          ariaLabel="dna-loading"
          wrapperClass="dna-wrapper"
        />
      </div>
    );
}

export default LoadingSpinner;




