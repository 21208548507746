import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import 'sweetalert2/dist/sweetalert2.min.css';
const ReactSwal = withReactContent(Swal);

const AlertPopup = async ({
  html,
  title,
  text,
  confirmButtonText = 'Confirm',
  confirmFunction,
  cancelButtonText = 'Cancel',
  cancelFunction,
  goBackButtonText = 'x',
  goBackFunction,
  ref
}) => {

  const result = await ReactSwal.fire({
    heightAuto: false,
    title,
    html,
    text,
    showCancelButton: Boolean(cancelFunction),
    showConfirmButton: Boolean(confirmFunction),
    showCloseButton: Boolean(goBackFunction),
    confirmButtonText,
    cancelButtonText,
    closeButtonHtml: goBackButtonText,
    allowOutsideClick: false,
    didOpen: () => {
      // Set the ref to the SweetAlert2 popup
      if (ref) {
        ref.current = ReactSwal;
      }
    },
  });

  if (result.isConfirmed && confirmFunction) {
    confirmFunction();
  } else if (result.dismiss === ReactSwal.DismissReason.cancel && cancelFunction) {
    cancelFunction();
  } else if (result.dismiss === ReactSwal.DismissReason.close && goBackFunction) {
    goBackFunction();
  }
};

export default AlertPopup;
