import React, { useState, useEffect, useRef } from 'react';
import '../styles/App.scss';
import "react-toggle/style.css" // for ES6 modules
import Toggle from 'react-toggle'
import TeamIVFCalendar from './Calendar/TeamIVFCalendar';
import "react-datepicker/dist/react-datepicker.css";
import PatientBlackoutForm from './forms/PatientBlackoutForm';
import CalendarForm from './forms/CalendarForm';
import {areDatesEqual, getArrayIdFromObject, isEmpty, regenerateEventsWithStimOffset} from '../js/common';
// import { ReactComponent as HFCHeader } from 'HFC_Header.svg';
import moment from 'moment';
import BlackoutModal from './forms/BlackoutModal';
import {CLINICAL_BLACKOUTS} from '../config'

import eventGenerator, { generateClinicalCalendarEvents, updateCurrentEventList } from '../js/eventGenerator';
import CommonModal from './common/CommonModal';
import EventEditor from './forms/EventEditor';
import ClinicalCalendar from './Calendar/ClinicalCalendar';
import MedicationDetails from './Calendar/MedicationDetails';

function getCalendarMode(){
  // REACT_APP_CALENDAR_MODE can be clinical or patient
  if (!isEmpty(process.env.REACT_APP_CALENDAR_MODE)) return process.env.REACT_APP_CALENDAR_MODE; 

  // temporarily support old url until we configure redirect
  if (new URLSearchParams(window.location.search).get('calendar-mode') === 'clinical'){
    return 'clinical';
  } else {
    return 'patient';
  }
}

function adminModeEnabled(){
  return new URLSearchParams(window.location.search).get('admin-mode') === '1';
}


function App() {

  const [events, setEvents] = useState([]);
  const [calendarConfig, setCalendarConfig] = useState({});
  const [calendarKey, setCalendarKey] = useState();


  const [showBlackoutConflictModal, setShowBlackoutConflictModal] = useState(false); 
  const [showEditorModal, setShowEditorModal] = useState(false); 
  const [conflictingDateRanges, setConflictingDateRanges] = useState(false); 
  const [calendarMode, setCalendarMode] = useState(getCalendarMode()); 
  const [adminMode] = useState(adminModeEnabled()); 

  const calendarContainerRef = useRef(null)
  const medicationDescriptionRef = useRef(null)

  useEffect(() => {
    if (calendarMode === 'clinical') {
      document.title = 'Team IVF Clinical Calendar Tool';
    } else {
      document.title = 'Team IVF Day 1 Calendar';
    }
  }, [calendarMode]);

 const onEventListPatch = (editedEvents) => {
  setEvents(updateCurrentEventList(events, editedEvents));
  setCalendarKey(new Date());

 }
 async function onFormSubmit(formData)  {

  const {startDate, patientBlackouts, patientInfo: {name = 'Patient', amh, weight, takingAntibiotics}, cycleReviewDatetime,monitoringVisits, stimStartDay} = formData;
    setCalendarConfig(formData);

    const momentStartDate = moment(startDate);
    const initialConflictingDateRanges = []
    let {events, conflictingDateRanges} = eventGenerator(momentStartDate, patientBlackouts, CLINICAL_BLACKOUTS, initialConflictingDateRanges, calendarMode, cycleReviewDatetime, monitoringVisits, stimStartDay);

    
    console.log('1111', events)

    setEvents(events);
    setConflictingDateRanges(conflictingDateRanges);

    setCalendarKey(new Date());

    if (!isEmpty(conflictingDateRanges)){
      setShowBlackoutConflictModal(true);
    } else {
      setShowBlackoutConflictModal(false);
    }
  }


  const editCalendarEvents = (editedEvents) => {
    setEvents(editedEvents);
    setCalendarKey(new Date());
  }
  const clearCalendar = () => {
    setConflictingDateRanges();
    setCalendarConfig({})
    setEvents([]);
    setCalendarKey(new Date());
  }

  const onCalendarToggle = () => {
    setCalendarKey(new Date())
    if (calendarMode === 'patient') {
      setCalendarMode('clinical');}
    else if (calendarMode === 'clinical') {
      setCalendarMode('patient');}
    }
    const scrollToDescription = () => {
      if (!isEmpty(medicationDescriptionRef.current)) {
        medicationDescriptionRef.current.scrollIntoView({ behavior: "smooth"});    
      }  
    }
  
  return (
    <div className="App">
      <div className='header-img-container w-100'>
        <img src="HFC_Header_Logo_only.svg" alt="hannam fertility center header logo with floral background" />
      </div>
      {calendarMode === 'patient' ?
      <>
        <h1 className='planning-header'> Planning your fertility journey</h1>
        <h3 className='planning-subheader'> Know what to expect and when.</h3>
      </>
        : 
        <h1 className='planning-header'> Clinician Calendar</h1>
      }
      <div className="full-page-flex">
        <CalendarForm 
          key={calendarKey}
          setCalendarKey={setCalendarKey}
          calendarKey={calendarKey}
          onFormSubmit={onFormSubmit}
          setCalendarConfig={setCalendarConfig}
          calendarConfig={calendarConfig}
          calendarMode={calendarMode}
          events={events} // used for generating default monitoring appts after calendar is generated
          clearCalendar={clearCalendar}
          onEventListPatch={onEventListPatch}
          adminMode={adminMode}
        ></CalendarForm>
          {!isEmpty(calendarConfig) && isEmpty(conflictingDateRanges) && !isEmpty(events) &&
            <>
              <hr></hr>
              {/* <div className='cal-toggle'>
                Enable Clinical Calendar:&emsp;
                <Toggle
                  defaultChecked={false}
                  icons={false}
                  onChange={onCalendarToggle} />
              </div>
              <br/> */}
                { calendarMode === 'patient' ? 
                    <TeamIVFCalendar events={events} innerRef={calendarContainerRef} startDate={calendarConfig.startDate} patientBlackouts={calendarConfig.patientBlackouts} calendarKey={calendarKey} setCalendarKey={setCalendarKey} patientInfo={calendarConfig.patientInfo} setShowEditorModal={setShowEditorModal} adminMode={adminMode}/>
                : <>
                    <ClinicalCalendar events={events} innerRef={calendarContainerRef} startDate={calendarConfig.startDate} patientBlackouts={calendarConfig.patientBlackouts} calendarKey={calendarKey} setCalendarKey={setCalendarKey} patientInfo={calendarConfig.patientInfo} setShowEditorModal={setShowEditorModal} scrollToDescription={scrollToDescription} onFormSubmit={onFormSubmit} calendarConfig={calendarConfig}/>
                    <MedicationDetails key={calendarKey} events={events} innerRef={medicationDescriptionRef} startDate={calendarConfig.startDate} patientBlackouts={calendarConfig.patientBlackouts} calendarKey={calendarKey} setCalendarKey={setCalendarKey} patientInfo={calendarConfig.patientInfo} setShowEditorModal={setShowEditorModal} stimStartDay={calendarConfig.stimStartDay}/>
                  </>
                }
              </>
          }  
        <div className='footer-img-container w-100'>
          <div className='svg-footer-container'> 
            <img src="Hannam-Fertility-Logo-White-cropped.svg" alt="hannam fertility center logo" />
          </div>
        </div>
        
      </div>


      {showBlackoutConflictModal && 
        <BlackoutModal
          calendarConfig={calendarConfig}
          onFormSubmit={onFormSubmit}
          setCalendarKey={setCalendarKey}
          conflictingDateRanges={conflictingDateRanges}
          onClose={(e)=>
              {
                if (e) {
                  e.preventDefault();
                }
                if (calendarContainerRef) {
                  // calendarContainerRef.scrollIntoView();    
                }
                setShowBlackoutConflictModal(false);
              }
            }
        />
        }
        {showEditorModal && 
          <EventEditor
            events={events} 
            calendarKey={calendarKey}
            setCalendarKey={setCalendarKey}
            onSubmit={editCalendarEvents}
            closeModal={() => setShowEditorModal(false)}
            calendarMode={calendarMode}
          >
          </EventEditor>
        }

    </div>
    );
}

export default App;
