import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { Col, Row } from 'react-bootstrap';
import { isEmpty, convertLbsToKg, convertKgToLbs, getThreeDaysAgo} from '../../js/common';
import DatePicker from "react-datepicker";
import DaySelector from './DaySelector';
import { getPatientLabAndDemographicData } from '../../js/accuro_utils';

function CalendarInput({ setPatientInfo, patientInfo, setCalendarKey, calendarMode, startDate, setStartDate, stimStartDay, setStimStartDay, adminMode}) {
  const [name, setName] = useState(patientInfo.name);
  const [amh, setAMH] = useState(patientInfo.amh);
  const [weight, setWeight] = useState(patientInfo.weight);
  const [takingAntibiotics, setPatientTakingAntibiotics] = useState(!isEmpty(patientInfo.takingAntibiotics) ?  patientInfo.takingAntibiotics : null);
  const [accuroId, setAccuroId] = useState('');

  const [errors, setErrors] = useState({});
  const onSubmit = (e) => {
    e.preventDefault();
    setPatientInfo({ ...patientInfo, name, amh: Math.ceil(amh), weight: weight, takingAntibiotics, accuroId}); // NOTE: Rounding the AMH value we use up
  };

    // Fetch data from Accuro API
    const handleFetchAccuroData = (e) => {
      e.preventDefault();
      getPatientLabAndDemographicData(accuroId)
        .then(data => {
          setAMH(data?.labData?.amh || '');
          setWeight(data?.labData?.weight || '');
          setName(data.demographics?.name)
          // setHeight(data.height || '');
        })
        .catch(error => {
          console.error('Error fetching data from Accuro API:', error);
          setErrors({ ...errors, accuroId: 'Failed to fetch data from Accuro API' });
        });
    };
  

  // enforce max/min for manual entry
  // amh can be 0-100
  const handleAMHChange = (e) => {
    const value = e.target.value;
      if (value == '' || (value >= 0 && value <= 100)) { // allow value of '' when clearing input
        setAMH(value);
        setErrors({ ...errors, amh: '' });
      } else {
        setErrors({ ...errors, amh: 'AMH must be between 0 and 100' });
      }
  };
  
  const handleChangeStimStart = (dayOfWeek) => {
    let stimOffset;

    switch (dayOfWeek.value) {
      case 'Monday':
        stimOffset = 0;
        break;
      case 'Saturday':
        stimOffset = -2;
        break;
      case 'Sunday':
        stimOffset = -1;
        break;
      default:
        console.log('No day selected');
    }
    setStimStartDay({stimStartDayOfWeek: dayOfWeek.value, stimOffset, label:dayOfWeek.label, value:dayOfWeek.value});

  };



  // enforce max/min for manual entry
  // weight can be 0+  
  const handleWeightChange = (e) => {
    const value = Math.max(0, e.target.value);
    setWeight(value);
  };

  const onAntibioticSelect = (e) => {
    setPatientTakingAntibiotics(e.target.value === 'true');
}

  return (
    <div className="patient-info-form">
      <Form>
        <Form.Group controlId="patientInfo">




          { calendarMode === 'clinical' && 
            <div className="accuro-fetch">
              <Form.Label>Enter Accuro ID:</Form.Label>
              <input
                value={accuroId}
                onChange={(e) => setAccuroId(e.target.value)}
                // required
              />
              &emsp;<button className="btn btn-secondary" onClick={handleFetchAccuroData}>Fetch Data</button>
              {!isEmpty(errors.accuroId) && <><br /><small className='form-error-text'>{errors.accuroId}</small></>}
            </div>
          }

          <div className="patient-info-row">
               {calendarMode === 'patient' ? <Form.Label>Enter your name:</Form.Label> : <Form.Label>Enter patient's name:</Form.Label>}
              <input 
                value={name} 
                onChange={(e) => setName(e.target.value)} 
                onBlur={onSubmit}
                // required 
              />
              {adminMode === true &&
                <>
                  <br/>
                  <Form.Label className='required'>Select Stim Start Day</Form.Label>
                  <div className='day-select-container'>
                    <DaySelector selectedDay={stimStartDay} onChange={handleChangeStimStart}/>
                  </div>
                </>
              }


            { calendarMode === 'clinical' && 
              <>

                <div>
                  <Form.Label className='required'> Pre-treatment Start:</Form.Label>&nbsp;
                  <DatePicker 
                    placeholderText="Select Start Date"
                    selected={startDate} 
                    value={startDate} 
                    onChange={(date) => setStartDate(date.setHours(0,0,0,0))} 
                    // minDate={getThreeDaysAgo() || null} // On 1-8-2023, we set 3 days ago to be the min date'
                    portalId="root"
                  />
                </div>
                <Form.Label className='required'>Enter AMH (pmol/L):</Form.Label>
                <input 
                  type="number" 
                  value={amh} 
                  onChange={handleAMHChange} 
                  onBlur={onSubmit}
                  min="0" 
                  max="100" 
                  required 
                />
                {errors.amh && <><br/><small className='form-error-text'>{errors.amh}</small></>}
                <br/>
                <Form.Label className='required'>Enter Weight (lbs):</Form.Label>
                <input 
                  type="number" 
                  value={weight} 
                  onChange={handleWeightChange} 
                  onBlur={onSubmit}
                  min="0" 
                  required 
                />
                <br/>
                <Form.Label className='required'>Select Stim Start Day</Form.Label>
                <div className='day-select-container'>
                  <DaySelector selectedDay={stimStartDay} onChange={handleChangeStimStart}/>
                </div>
                <br/>


                <Form.Label className='required'>Will patient be on the Amoxi-clav protocol (antibiotics)?</Form.Label><br />
                <div className='antibiotics inline-radio'>
                      <Form.Check
                          checked={takingAntibiotics===false}
                          type="radio"
                          label="No"
                          value={false}
                          name="radioGroup" 
                          id="radioOption2"
                          onChange={onAntibioticSelect}
                          onBlur={onSubmit}
                         
                      />

                      <Form.Check
                          checked={takingAntibiotics===true}
                          type="radio"
                          label="Yes"
                          value={true}
                          name="radioGroup" 
                          id="radioOption1"
                          onChange={onAntibioticSelect}
                          onBlur={onSubmit}
                          
                          />
                  </div>

              </>
            }


          </div>
        </Form.Group>
      </Form>
    </div>
  );
}

export default CalendarInput;
