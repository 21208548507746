import React, {useState} from 'react';
import {OverlayTrigger, Popover} from "react-bootstrap";

const CalendarEventPopover = ({ position, title, body, children, headerClassName = '' }) => {

    const CustomPopover = (
        <Popover id={title} className='popover'>
            {title && <div className={`popover-header ${headerClassName}`}>
                <h6>{title}</h6>
            </div>}
            {body && <Popover.Body>{body}</Popover.Body>}
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger={['click', 'hover']}
            placement={'auto'}

            overlay={CustomPopover}
            rootClose
        >
            {children}
        </OverlayTrigger>
    );
};
export default CalendarEventPopover;