import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function CommonModal({title, body, onClose}) {

  return (
    <Modal show={true} onHide={() => onClose()} centered={true}>
    <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{body}</Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
            Close
        </Button>
    </Modal.Footer> 

    </Modal>
  );
}

export default CommonModal;