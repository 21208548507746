// Import PapaParse
import Papa from 'papaparse';
// Import the CSV data string
import pergoverisLookupString from './pergoveris_lookup.js';
import { convertLbsToKg } from '../common.js';

// Function to parse CSV data
function parseCsvData() {
  const csvString = pergoverisLookupString;
  return new Promise((resolve, reject) => {
    Papa.parse(csvString, {
      header: true,
      skipEmptyLines: true,
      complete: function(results) {
        resolve(results.data);
      },
      error: function(error) {
        reject(error);
      }
    });
  });
}

// Function to calculate Pergoveris dosage
const calculatePergoverisDosage = (amh, weightLbs, csvData) => {
  const weightKg = convertLbsToKg(weightLbs);
  // Adjust weight if less than 43kg and round to nearest whole number
  const pergoverisWeightKg = Math.round(Math.max(weightKg, 43));

  // Filter CSV data
  const dosageRow = csvData.find(row => row['Weight(kg)'] == pergoverisWeightKg && row['AMH'] == amh);

  let dosage;
  if (dosageRow) {
    // If dosageRow is not empty, get the first dosage value
    dosage = dosageRow['Dosage'];
  } else if (amh > 40) {
    // If dosageRow is empty and AMH is greater than 40, get the dosage for AMH value 40
    const dosageRow40 = csvData.find(row => row['Weight(kg)'] == pergoverisWeightKg && row['AMH'] == 40);
    dosage = dosageRow40 ? dosageRow40['Dosage'] :  `<b class="highlighted">(Unknown Dose - review AMH/Weight)</b>`;
  } else {
    // If no match found and AMH is not greater than 40
    dosage = `<b class="warning-text">(Unknown Dose - review AMH/Weight)</b>`;
  }

  return dosage;
};

// Main function to get Pergoveris Dose
export default async function getPergoverisDose(amh, weight) {
  try {
    const csvData = await parseCsvData();
    const dosage = calculatePergoverisDosage(amh, weight, csvData);
    console.log('Calculated Dosage:', dosage);
    return `${dosage}IU`;
  } catch (error) {
    console.error('Error getting Pergoveris dose:', error);
    throw error;
  }
}