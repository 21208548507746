import React from 'react';
import CalendarEventPopover from '../common/InfoPopover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { isEmpty } from '../../js/common';

/** Replacing the original FullCalendar Event elements, wrapped with our Popover. See the below link for the Event. 4
 * Not a great practice (this code may need to be updated if the library updates), 
 * but needs to be done to override default FullCalendar event to include a popover. 
 * https://github.com/fullcalendar/fullcalendar/blob/495d925436e533db2fd591e09a0c887adca77053/packages/common/src/common/StandardEvent.tsx#L79
 */
export default function EventContent( {innerProps, printView}  ) {
    const printWidth = printView ? {width: 961} : undefined
    const isDesktop = useMediaQuery({ query: `(min-width: 961px)` }, printWidth);
    const {title='', extendedProps={}, classNames=[] } = innerProps.event;

    if (isDesktop) {
      // console.log(extendedProps)
      // console.log('ddddd')

      return (
        <div className='fc-event-main-frame'>
            { innerProps.timeText &&
            <div className='fc-event-time'>{ innerProps.timeText }</div>
            }
            <div className='fc-event-title-container w-100'>
              <div className='fc-event-title fc-sticky w-100'>
                <span className='event-title-group event-title-banner'>
                {(extendedProps['type'] === 'icon-button' && extendedProps['icon']) ?

                // icon-only events
                <>
                  &nbsp;&nbsp;<CalendarEventPopover position={'top'} title={title} body={<div dangerouslySetInnerHTML={{__html: extendedProps['description']}}/>} headerClassName={classNames[0]}> 
                    <span className='clickable-container'><button className="btn event-btn-mobile">{extendedProps['icon']}</button> </span>
                  </CalendarEventPopover> 
                </> : 

                // other events
                <>
                  {extendedProps['icon'] && <>&nbsp;{extendedProps['icon']}</>}
                  {title && <>&nbsp;&nbsp;{title}</>}
                  {extendedProps['description'] && <>&nbsp;&nbsp;<CalendarEventPopover position={'top'} title={title} body={<div dangerouslySetInnerHTML={{__html: extendedProps['description']}}/>} headerClassName={classNames[0]}> 
                    <span className='clickable-container'><FontAwesomeIcon icon={faCircleInfo}/> </span>
                  </CalendarEventPopover></> }
                </>
                }
                </span>
              </div>
  
            </div>
        </div>
    );
    } else { // mobile view -- no titles on background events, and remove info icon for descriptions w/icons
      // console.log(111, extendedProps)
      // console.log('mmmm')
      return (
         <div className='fc-event-main-frame'>
            { innerProps.timeText &&
            <div className='fc-event-time'>{ innerProps.timeText }</div>
            }
            <div className='fc-event-title-container'>
              <div className='fc-event-title fc-sticky'>
              {/* {['auto'].includes(innerProps.event.display) && title && <>&nbsp;&nbsp;{title}</>} */}
              {['strip'].includes(innerProps.event.display) && title && <span className='title-text'>&nbsp;&nbsp;{title}&nbsp;&nbsp;</span>}

                { extendedProps['description'] ?
                <span className='event-title-group'>

                      <CalendarEventPopover position={'top'} title={title} body={<div dangerouslySetInnerHTML={{__html: extendedProps['description']}}/>} headerClassName={classNames[0]}> 
                        <button className="btn event-btn-mobile">
                          <span className='clickable-container'>
                            {extendedProps['icon'] ? extendedProps['icon'] : <FontAwesomeIcon icon={faCircleInfo} size='xs'/>}
                          </span>
                        </button>
                      </CalendarEventPopover> 
                    </span> : 
                      extendedProps['icon'] && <button className="btn event-btn-mobile">{extendedProps['icon']}</button>
                }
              </div>
  
            </div>
        </div>
    );

    }
  }
