import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import InfoPopover from '../common/InfoPopover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Col, Row } from 'react-bootstrap';
import { getDateAtMorning, getThreeDaysAgo } from '../../js/common';

function PatientDay1Input({ setStartDate, startDate, calendarMode = 'patient' }) {
  const onSubmit = (date) => {
    setStartDate(date);
  }

  return (
    <div className="datepicker-container">
      <DatePicker
        inline={calendarMode === 'patient'}
        placeholderText="Select Start Date"
        selected={startDate}
        onChange={(date) => onSubmit(date)}
        minDate={getThreeDaysAgo() || null}
        portalId="root"
        dateFormat="MM/dd/yyyy"
      />
    </div>
  );
}

function ClinicAppointmentInputs({ cycleReviewDatetime, setCycleReviewDatetime, monitoringVisits, setMonitoringVisits, startDate }) {
  const handleDateChange = (date, index) => {
    if (index === 'cycle') {
      setCycleReviewDatetime({...cycleReviewDatetime, date});
    } else {
      // setMonitoringVisits(prev => ({ ...prev, [index]: { date, time: prev[index]?.time } }));
      setMonitoringVisits(  {...monitoringVisits, [index]: { ...monitoringVisits[index], date } });
    }
  }

  const handleTimeChange = (time, index) => {
    if (index === 'cycle') {
      setCycleReviewDatetime({...cycleReviewDatetime, time});
    } else {
      // setMonitoringVisits(prev => ({ ...prev, [index]: { date: prev[index]?.date, time } }));
      setMonitoringVisits(  {...monitoringVisits, [index]: { ...monitoringVisits[index], time } });
    }
  }

  return (
    <div className="clinical-datepicker-container justify-content-start">
      <Form.Label>
        <p><b>Please submit at the bottom of this form once you are satisifed with your dates and times.</b> <br/>Monitoring Appt dates have been prefilled for convenience. </p>
         </Form.Label>
      <div>
        <Form.Label><b>Cycle Orientation</b></Form.Label><br/>
        <Form.Label> Date:&nbsp;</Form.Label>
        <DatePicker
          placeholderText="Select Date"
          selected={cycleReviewDatetime?.date}
          onChange={(date) => handleDateChange(date, 'cycle')}
          openToDate={getDateAtMorning(startDate)}
          minDate={getThreeDaysAgo() || null}
          portalId="root"
          dateFormat="MM/dd/yyyy"
        /><br/>
        <Form.Label> Time:&nbsp;</Form.Label>
        <DatePicker
          selected={cycleReviewDatetime?.time}
          onChange={(time) => handleTimeChange(time, 'cycle')}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          minTime={new Date().setHours(7, 0)}
          maxTime={new Date().setHours(17, 0)}
          timeCaption="Time"
          dateFormat="h:mmaa"
          timeFormat="h:mmaa"
          placeholderText="Select Time"
          openToDate={getDateAtMorning(startDate)}
        />
      </div>
      <br/>
      {[1, 2, 3, 4].map((num) => (
        <div key={num}>
          <Form.Label><b>Monitoring Appt {num}</b></Form.Label><br/>
          <Form.Label>Date:&nbsp;</Form.Label>
          <DatePicker
            placeholderText="Select Date"
            selected={monitoringVisits[`clinic-visit-monitoring-${num}`]?.date}
            onChange={(date) => handleDateChange(date, `clinic-visit-monitoring-${num}`)}
            minDate={getDateAtMorning(startDate) || null}
            portalId="root"
            dateFormat="MM/dd/yyyy"
            openToDate={monitoringVisits[`clinic-visit-monitoring-${num}`]?.date}
          /><br/>
          <Form.Label> Time:&nbsp;</Form.Label>
          <DatePicker
            selected={monitoringVisits[`clinic-visit-monitoring-${num}`]?.time}
            onChange={(time) => handleTimeChange(time, `clinic-visit-monitoring-${num}`)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            minTime={new Date().setHours(7, 0)}
            maxTime={new Date().setHours(17, 0)}
            timeCaption="Time"
            dateFormat="h:mmaa"
            timeFormat="h:mmaa"
            placeholderText="Select Time"
            openToDate={getDateAtMorning(startDate)}
          /><br/>
        </div>
      ))}
    </div>
  );
}

function PatientDay1Wrapper({ setStartDate, startDate, calendarMode, setCycleReviewDate, cycleReviewDate, setCycleReviewTime, cycleReviewTime, monitoringVisits = {}, setMonitoringVisits }) {
  return (
    <div className="calendar-input-form">
      <Form>
        <Form.Group className="day-1-input-wrapper" controlId="formBasicEmail">
          <Row className="day-1-input-group">
            {calendarMode === 'patient' &&

              <>
                <Col sm="12" md="12" lg="6">
                  <Form.Label className='required'>
                    Select your Day 1 <br /> (the expected start date of your next period):
                    &nbsp;&nbsp;
                    <InfoPopover position={'top'} body={'Don\'t get regular periods? Please select your preferred start date in the calendar.'}>
                      <span className='clickable-container'><FontAwesomeIcon icon={faCircleInfo} /> </span>
                    </InfoPopover>
                  </Form.Label>
                </Col>
                <Col sm="12" md="12" lg="6">
                  <PatientDay1Input setStartDate={setStartDate} startDate={startDate} calendarMode={calendarMode}></PatientDay1Input>
                </Col>
              </>
            }
            {calendarMode === 'clinical' &&
              <Row className='day-1-input-group'>
                <Col sm="12" md="12" lg="12">
                  <ClinicAppointmentInputs
                    startDate={startDate}
                    setCycleReviewDate={setCycleReviewDate}
                    cycleReviewDate={cycleReviewDate}
                    setCycleReviewTime={setCycleReviewTime}
                    cycleReviewTime={cycleReviewTime}
                    monitoringVisits={monitoringVisits}
                    setMonitoringVisits={setMonitoringVisits}
                  />
                </Col>
              </Row>
            }
          </Row>
        </Form.Group>
      </Form>
    </div>
  );
}

export {
  PatientDay1Input,
  PatientDay1Wrapper,
  ClinicAppointmentInputs
};
