import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf";
import { isBrowser, isMobile } from 'react-device-detect';
import PDFMerger from 'pdf-merger-js';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib'

// hide inset boxshadow in pdf, as they're busted
// currently used in background events in full calendar
const hideInsetBoxShadow = (doc) => {
  const bgEvents = doc.getElementsByClassName('fc-bg-event');
  for (let i=0;i<bgEvents.length;i++){
      bgEvents[i].style.boxShadow = 'none';
  }

  const dayCells = doc.getElementsByTagName('td');
  for (let i=0;i<dayCells.length;i++){
    dayCells[i].style.boxShadow = 'none';
  }

  const tables = doc.getElementsByTagName('table');
  for (let i=0;i<tables.length;i++){
    tables[i].style.boxShadow = 'none';;
  }

  const loadingPage = doc.getElementsByClassName('spinner-wrapper');
  if (loadingPage[0]) loadingPage[0].style.display = 'none';

  // const calWizard = doc.getElementsByClassName('calendar-wizard-wrapper');
  // if (calWizard[0]) calWizard[0].style.display = 'none';
}

// // show mobile titles
// const showHiddenTitles = (doc) => {
//   const eventTitles = doc.getElementsByClassName('pdf-hidden-title');
//   for (let i=0;i<eventTitles.length;i++){
//     eventTitles[i].style.display = 'inline';
//   }
// }

async function generateCalendarPDF(fileName, classSelector) {      
  return new Promise((resolve) => {
    html2canvas(
      document.querySelector(classSelector), 
      { 
        'onclone': (doc) => hideInsetBoxShadow(doc), 
        'scale':'1'
      }).then(canvas => {
      let dataURL = canvas.toDataURL('image/png');

      const pdf = new jsPDF({
        orientation: 'portrait',
      });
      const imgProps= pdf.getImageProperties(dataURL);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      // fit in header above image, by shifting the height by 40, and scaling the width down too to avoid distortion
      pdf.addImage('calendar-header-png.PNG', 'PNG', 0, 0, pdf.internal.pageSize.getWidth(),40 ); 
      pdf.addImage(dataURL, 'PNG', 10, 40, pdfWidth-20, pdfHeight-40, 'calendar-img', 'MEDIUM');

      resolve(pdf);

  })}
)}

async function generateCalendarPDFWithInfo(fileName, classSelector, originalWidth) {      
  const pdfDoc = await PDFDocument.create();
  // Generate the PDF from your component
  const calendarPdf = await generateCalendarPDF(fileName, classSelector);
  const calendarPdfBytes = await calendarPdf.output('arraybuffer');
  const [calendar] = await pdfDoc.embedPdf(calendarPdfBytes);
  const calendarFlagDims = calendar.scale(1);


  const calendarPdfToEmbed = await PDFDocument.load(calendarPdfBytes);
  const calendarPdfPage = await pdfDoc.embedPage(calendarPdfToEmbed.getPages()[0]);
  var page = pdfDoc.addPage();
  page.drawPage(calendar, {
    ...calendarFlagDims,
    x: page.getWidth() / 2 - calendarFlagDims.width / 2,
    y: page.getHeight() - calendarFlagDims.height ,
  });


  const infoUrl = '2024-01 TeamIVF_Calendar_InfoPDF_V6_ICONS.pdf';
  const infoPdfBytes = await fetch(infoUrl).then((res) => res.arrayBuffer());
  const infoPdf = await PDFDocument.load(infoPdfBytes);

  // Embed the first page of the calendar
  const infoPage = await pdfDoc.embedPage(infoPdf.getPages()[0]);
  var page = pdfDoc.addPage();
  page.drawPage(infoPage, {
    x: 0,
    y: 50, // Start from the bottom-left corner
  });


  // Serialize the PDFDocument to bytes (a Uint8Array)
  const pdfBytes = await pdfDoc.save();
  saveByteArray(fileName, pdfBytes);
  // document.querySelector('meta[name=viewport]').setAttribute("content", originalViewport);
  document.querySelector('.seven-week-calendar-container').style.width = originalWidth;
  
  return true;

}



function saveByteArray(reportName, byte) {
  var blob = new Blob([byte], {type: "application/pdf"});
  var link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
};


function promiseResolveAfterKeyChange(regenerateKey, setPrintView) {
  return new Promise((resolve) => {

    const printWidth = '1500px';
    setPrintView(true); // communicates with EventContent to render Desktop UI version of events, despite the viewport not changing
    document.querySelector('.seven-week-calendar-container').style.width = printWidth; // forces the calendar DOM to extend to the intended pdf width

    // console.log('new width', document.querySelector('.seven-week-calendar-container').style.width)
    regenerateKey(new Date()); // currently may show blackout modal in a spammy way
    resolve(true);
  
  });
}

async function exportToPDF(fileName, classSelector, regenerateKey, setIsLoading, setPrintView){ 
  const originalWidth = document.querySelector('.seven-week-calendar-container').style.width;
  // console.log('original width', originalWidth);

    // switch to print view for the PDF print
    await promiseResolveAfterKeyChange(regenerateKey, setPrintView);

    // builds a screenshot of the component from the DOM, and fits it into a PDF
    // if in mobile, revert back to mobile view 
    // HACK: Adding an empty timeout seems to force the PDF generation to use the correct (print view) DOM on mobile
    setTimeout(async () => {
      await generateCalendarPDFWithInfo(fileName, classSelector, originalWidth);
      setIsLoading(false);
      setPrintView(false); 
    }, 500);

};
    
    
export {
  exportToPDF
}