import { faSyringe, faPills, faPhone, faEgg, faHospital, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CalendarEventPopover from '../Components/common/InfoPopover';
import {isWeekend,isMonday,getNextMonday, isFriday, isEmpty, getDateAfterDays, getDefaultMonitoringAppointments, getArrayIdFromObject} from './common';

import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { getPhase1Meds, getPhase2Meds, getPhase3Meds, getPhase4Meds } from './medications';
const moment = extendMoment(Moment); // wrap moment with a date range library (for handling blackout dates)

// check if a date range falls on a clinical/patient blackout date
// if date range only includes a start date, use it as the end date too
// expected range input: {range: {'start': DATE, 'end': DATE}, type}
const checkRangeConflicts = (eventRanges, blackoutRanges) => {
    const conflicts = [];
    for (const eventRangeIdx in eventRanges) {
      for (const blackoutRangeIdx in blackoutRanges) {

        const blackoutRange = blackoutRanges[blackoutRangeIdx].range;
        const isBlackoutConflict = eventRanges[eventRangeIdx].overlaps(blackoutRange, {adjacent: true});

        if (isBlackoutConflict) {
          // console.log('event', eventRangeIdx, eventRanges[eventRangeIdx])
          // console.log('blackout', blackoutRanges[blackoutRangeIdx] )

          // only add conflicting range if it does not already exist
          if (isEmpty(conflicts)){
            conflicts.push(blackoutRanges[blackoutRangeIdx]);
          } else {
            const isConflictUnique = checkIfConflictExists(blackoutRange, conflicts);
            if (isConflictUnique){
              conflicts.push(blackoutRanges[blackoutRangeIdx]);
            }
          } 
           
        }
      }
    }
    return conflicts;
}

const checkIfConflictExists = (blackoutRange, conflicts) => {
  for (const existingConflictIdx in conflicts){
    const existingRange = conflicts[existingConflictIdx].range
    if (!blackoutRange.isSame(existingRange)){
      return true;
    }
  }
  return false;
}

const generateBlackoutRanges = (blackoutDates=[], type) => {
  const blackoutRanges = {};
  for (let i=0; i<blackoutDates.length;i++){
    const blackoutStart = moment(blackoutDates[i]['start']);
    const blackoutEnd = moment(blackoutDates[i]['end']);
    const blackoutRange = moment.range(blackoutStart, blackoutEnd);
    blackoutRanges[i] = {range: blackoutRange, type};
  }
  return blackoutRanges;
}
const checkIfPatientBlackoutConflicts = (ivfPhaseDates, patientBlackoutDates) => {
  const {    
    preTreatmentStart, preTreatmentEnd, stimulationStart, stimulationEnd, triggerRetrievalStart, triggerRetrievalEnd, recoveryStart, recoveryEnd,
  } = ivfPhaseDates;
  const type = 'patient';

  // First day of phase 1, all of phase 2, and all of phase 3 must not conflict. 
  const eventRanges = {
    phase1Pre: moment.range(preTreatmentStart, preTreatmentStart),
    phase2Stim: moment.range(stimulationStart, stimulationEnd),
    phase3Trigger: moment.range(triggerRetrievalStart, triggerRetrievalEnd),
  }
  const blackoutRanges = generateBlackoutRanges(patientBlackoutDates, type);
  const eventConflictsWithBlackout = checkRangeConflicts(eventRanges, blackoutRanges);
  return eventConflictsWithBlackout;
}
const checkIfClinicalBlackoutConflicts = (ivfPhaseDates, clinicalBlackoutDates) => {
  const {    
    preTreatmentStart, preTreatmentEnd, stimulationStart, stimulationEnd, triggerRetrievalStart, triggerRetrievalEnd, recoveryStart, recoveryEnd,
  } = ivfPhaseDates;
  const type = 'clinic';

  // all of phase 2, and all of phase 3 must not conflict. 
  const eventRanges = {
    phase2Stim: moment.range(stimulationStart, stimulationEnd),
    phase3Trigger: moment.range(triggerRetrievalStart, triggerRetrievalEnd),
  }
  const blackoutRanges = generateBlackoutRanges(clinicalBlackoutDates, type);
  const eventConflictsWithBlackout = checkRangeConflicts(eventRanges, blackoutRanges, type);
  return eventConflictsWithBlackout;
}

// if date falls on a patient/clinical blackout date, postpone to next cycle (28 days)
const postponeToNextCycle = (date) => {
  return date.clone().add(27, 'd');
}


//patient: firstDayOfCycle + 3/4/5d, as long as not weekend or blackout
//clinical: take day1 as pretreatment start as it is
const getPreTreatmentStart = (firstDayOfCycle, calendarMode) => {
  if (calendarMode === 'clinical'){
    return moment(firstDayOfCycle).clone();
  } else {
    let preTreatmentStart = moment(firstDayOfCycle).clone().add(2, 'd');

    // if preTreatmentStart is on a weekend, change it to the next monday
    if (isWeekend(preTreatmentStart)){
      preTreatmentStart = getNextMonday(preTreatmentStart);
    }
  
    return preTreatmentStart;
    }
}

// first monday after preTreatmentStart + 18d (duration is 18/19/20/21/22 days)
const getPreTreatmentEnd = (preTreatmentStart) => {
  let preTreatmentEnd = preTreatmentStart.clone().add(17, 'days'); 
  if (isMonday(preTreatmentEnd)){
    return preTreatmentEnd.clone();
  } else {
    return getNextMonday(preTreatmentEnd); 
  }
}

// first monday after getPreTreatmentEnd
const getStimStart = (preTreatmentEnd) => {
  return getNextMonday(preTreatmentEnd);
}

// getStimStart + 8d
const getStimEnd = (stimStart) => {
  return stimStart.clone().add(7, 'days');
}
// getStimEnd + 1d
const getTriggerRetrievalStart = (stimEnd) => { 
  return stimEnd.clone().add(1, 'days');
}

// getTriggerRetrievalStart + 6d
const getTriggerRetrievalEnd = (triggerRetrievalStart) => {
  return triggerRetrievalStart.clone().add(5, 'days'); 
}

// Q: can this occur on weekend or blackout?
// getRetrieval + 1d
const getRecoveryStart = (triggerRetrievalEnd) => {
  return triggerRetrievalEnd.clone().add(1, 'days');; 
}

// Q: can this occur on weekend or blackout?
// getRecoveryStart + 7d
const getRecoveryEnd = (recoveryStart) => {
  return recoveryStart.clone().add(6, 'days');
}

//last mon-fri of pretreatment
const getCycleOrientationRange = (preTreatmentEnd) => {
  if (preTreatmentEnd.day() === 6){ // if end date of pretreatment is sat, use the same week's mon-fri
    const start = preTreatmentEnd.clone().startOf('week').add(1, 'days'); // Monday
    const end = start.clone().add(4, 'days'); // Friday
    return { start, end };
  } else { // use prev week's mon-fri, as it is the last full week of pretreatment
    const start = preTreatmentEnd.clone().subtract(1, 'weeks').startOf('week').add(1, 'days'); // Monday
    const end = start.clone().add(4, 'days'); // Friday
    return { start, end };
  }
}

// standard case: preTreatmentStart -> preTreatmentStart+2d
// if friday is preTreatmentStart, preTreatmentStart -1 -> preTreatmentStart+1d
const getStayClose1Range = (preTreatmentStart) => {
  if (!isFriday(preTreatmentStart)){
    return {
      start: preTreatmentStart.clone(),
      end: preTreatmentStart.clone().add(1, 'days'),
    }
  } else {
    return {
      start: preTreatmentStart.clone().subtract(1, 'days'),
      end: preTreatmentStart.clone(),
    }
  }
}



const generateEventDates = (firstDayOfCycle, calendarMode, stimStartDayAndOffset) => {
  const preTreatmentStart = getPreTreatmentStart(firstDayOfCycle, calendarMode); // if calendarMode is clinical, treat day 1 as pretreatment start date
  let preTreatmentEnd = getPreTreatmentEnd(preTreatmentStart);
  let stimulationStart = getStimStart(preTreatmentEnd);

  // if not a monday start, stim offset applies to pretreatment end & stim start. the subsequent events that need to be cascaded will point to these offset dates when generating for the first time
  if (!isEmpty(stimStartDayAndOffset)){
    if (stimStartDayAndOffset['stimOffset'] > 0) {
      preTreatmentEnd = preTreatmentEnd.clone().add(Math.abs(stimStartDayAndOffset['stimOffset']), 'days');
      stimulationStart = stimulationStart.clone().add(Math.abs(stimStartDayAndOffset['stimOffset']), 'days');
    } else if (stimStartDayAndOffset['stimOffset'] < 0) {
      preTreatmentEnd = preTreatmentEnd.clone().subtract(Math.abs(stimStartDayAndOffset['stimOffset']), 'days');
      stimulationStart = stimulationStart.clone().subtract(Math.abs(stimStartDayAndOffset['stimOffset']), 'days');
    }
  }

  const stimulationEnd = getStimEnd(stimulationStart);

  const triggerRetrievalStart = getTriggerRetrievalStart(stimulationEnd);
  const triggerRetrievalEnd = getTriggerRetrievalEnd(triggerRetrievalStart);
  const recoveryStart = getRecoveryStart(triggerRetrievalEnd);
  const recoveryEnd = getRecoveryEnd(recoveryStart);

  const noMeds1Duration = getDayDuration(firstDayOfCycle, preTreatmentStart);
  const noMeds2Duration = getDayDuration(preTreatmentEnd, stimulationStart);
  const noMeds3Duration = 1;

  const cycleOrientationRange = getCycleOrientationRange(preTreatmentEnd);


  const stayClose1Range = getStayClose1Range(preTreatmentStart);
  const stayClose2Duration = 21;
  
  // clinic visit days. To prevent duplication, we use a recurring event
  const preTreatmentVisit = preTreatmentStart.clone(); // first day of pre-treatment
  const stimWeek1Visits = [1,5]; // monday, friday
  const stimWeek2Visits = [1]; // monday

  const eventList = {
    preTreatmentStart,
    preTreatmentEnd,
    stimulationStart,
    stimulationEnd,
    triggerRetrievalStart,
    triggerRetrievalEnd,
    recoveryStart,
    recoveryEnd,
    noMeds1Duration,
    noMeds2Duration,
    noMeds3Duration,
    cycleOrientationRange,
    stayClose1Range,
    stayClose2Duration,
    preTreatmentVisit,
    stimWeek1Visits,
    stimWeek2Visits,
  };

  return eventList;
}
const getDayDuration = (firstDay, secondDay) => {
  return Math.abs(firstDay.diff(secondDay, 'days'));
}


export default function eventGenerator(firstDayOfCycle, patientBlackouts=[], clinicalBlackouts, conflictingDateRanges, calendarMode, cycleReviewDatetime, monitoringVisits={}, stimStartDayAndOffset){
  // console.log('conflicts....', conflictingDateRanges)
  const patientDay1 = firstDayOfCycle.clone();
  const ivfPhaseDates = generateEventDates(patientDay1, calendarMode, stimStartDayAndOffset);
  const patientBlackoutConflicts = checkIfPatientBlackoutConflicts(ivfPhaseDates, patientBlackouts);
  const clinicBlackoutConflicts = checkIfClinicalBlackoutConflicts(ivfPhaseDates, clinicalBlackouts);
  
  if (!isEmpty(patientBlackoutConflicts)) {
    // only retain first set of blackout conflicts, as the recursive eventGenerator would lose context of the original date request
    const firstConflictingDateRanges = isEmpty(conflictingDateRanges) ?  patientBlackoutConflicts.concat(clinicBlackoutConflicts,)  : conflictingDateRanges;
    console.log('first conflict patient', firstConflictingDateRanges)
    return eventGenerator(postponeToNextCycle(firstDayOfCycle.clone()), patientBlackouts, clinicalBlackouts, firstConflictingDateRanges, calendarMode, cycleReviewDatetime,monitoringVisits, stimStartDayAndOffset);
  }
  if (!isEmpty(clinicBlackoutConflicts)){
    // only retain first set of blackout conflicts, as the recursive eventGenerator would lose context of the original date request
    const firstConflictingDateRanges = isEmpty(conflictingDateRanges) ?  patientBlackoutConflicts.concat(clinicBlackoutConflicts)  : conflictingDateRanges;
    console.log('first conflict clinic', firstConflictingDateRanges)
    return eventGenerator(postponeToNextCycle(firstDayOfCycle.clone()), patientBlackouts, clinicalBlackouts, firstConflictingDateRanges, calendarMode, cycleReviewDatetime,monitoringVisits, stimStartDayAndOffset);
  }
  const {    
    preTreatmentStart, preTreatmentEnd, stimulationStart, stimulationEnd, triggerRetrievalStart, triggerRetrievalEnd, recoveryStart, recoveryEnd,
    noMeds1Duration, noMeds2Duration, noMeds3Duration, cycleOrientationRange, stayClose1Range, stayClose2Duration, preTreatmentVisit, stimWeek1Visits, stimWeek2Visits
  } = ivfPhaseDates;

  // NOTE: momentJS treats 12am as the next day, while FullCalendar treats 12am as the previous day (only for event end dates)
  // Therefore, 1-day padding is necessary when adding end dates to FullCalendar
  const events = [      
      {
        id: 'cycle-orientation',
        title: 'Cycle Orientation',
        start: cycleOrientationRange['start'].clone().format('YYYY-MM-DD'),
        end: cycleOrientationRange['end'].clone().add(1, 'days').format('YYYY-MM-DD'),
        allDay:true,
        extendedProps:{
          description:
          `<ul> 
            <li>You will have a virtual appointment with a nurse via Zoom to discuss your treatment plan and medications (before starting injections), review general information about the cycle, and to answer questions you may have. </li>
            <li>Cycle payment will be due by the orientation date.</li>
          </ul>`,
    },  
        className : ["cycle-orientation"],
        textColor: 'black',
        display:'strip',
        borderColor: 'black',
      },
      {
        id: 'stay-close-0',
        title: 'Stay Close (Required)',
        start: stayClose1Range['start'].clone().format('YYYY-MM-DD'),
        end: stayClose1Range['end'].clone().add(1, 'days').format('YYYY-MM-DD'),
        allDay:true,
        className : ["blackout"],
        textColor: 'white',
        display:'strip',
        borderColor: 'black',
        extendedProps:{
          description:'<b>No Travel.</b> These dates are typically clinic visit dates and you will be required to come in. You must stay nearby during this time.'
        },  

      },
      // {
      //   id: 'stay-close-2',
      //   title: 'Stay Close (Required)',
      //   start: stimulationStart.clone().format('YYYY-MM-DD'),
      //   allDay:true,
      //   className : ["blackout"],
      //   textColor: 'white',
      //   display:'strip',
      //   borderColor: 'black',
      //   extendedProps:{
      //     description:'<b>No Travel.</b> These dates are typically clinic visit dates and you will be required to come in. You must stay nearby during this time.'
      //   },  
      // },
      // {
      //   id: 'stay-close-3',
      //   title: 'Stay Close (Required)',
      //   start: stimulationStart.clone().add(4, 'days').format('YYYY-MM-DD'),
      //   allDay:true,
      //   className : ["blackout"],
      //   textColor: 'white',
      //   display:'strip',
      //   borderColor: 'black',
      //   extendedProps:{
      //     description:'<b>No Travel.</b> These dates are typically clinic visit dates and you will be required to come in. You must stay nearby during this time.'
      //   },  
      // },
      // {
      //   id: 'stay-close-4',
      //   title: 'Stay Close (Required)',
      //   start: triggerRetrievalStart.clone().subtract(1, 'days').format('YYYY-MM-DD'),
      //   end: triggerRetrievalEnd.clone().add(4, 'days').format('YYYY-MM-DD'),  //fullcalendar end dates are exclusive, so pad by 1 https://fullcalendar.io/docs/event-object
      //   allDay:true,
      //   allDay:true,
      //   className : ["blackout"],
      //   textColor: 'white',
      //   display:'strip',
      //   borderColor: 'black',
      //   extendedProps:{
      //     description:'<b>No Travel.</b> These dates are typically clinic visit dates and you will be required to come in. You must stay nearby during this time.'
      //   },  
      // },
      // {
      //   id: 'greyout-1',
      //   title: 'Stay Close (Recommended)',
      //   start: stimulationStart.clone().add(1, 'days').format('YYYY-MM-DD'), // day 2 - 4 of stim are grey
      //   end: stimulationStart.clone().add(4, 'days').format('YYYY-MM-DD'),
      //   allDay:true,
      //   className : ["greyout"],
      //   textColor: 'white',
      //   display:'strip',
      //   borderColor: 'black',
      //   extendedProps:{
      //     description:'These dates are not typically clinic visit dates but it is possible you may need to come in. We recommend that you plan to stay nearby during this time.'
      //   },  
      // },
      // {
      //   id: 'greyout-2',
      //   title: 'Stay Close (Recommended)',
      //   start: stimulationStart.clone().add(5, 'days').format('YYYY-MM-DD'), // sat/sun of stim are grey too
      //   end: stimulationStart.clone().add(7, 'days').format('YYYY-MM-DD'),
      //   allDay:true,
      //   className : ["greyout"],
      //   textColor: 'white',
      //   display:'strip',
      //   borderColor: 'black',
      //   extendedProps:{
      //     description:'These dates are not typically clinic visit dates but it is possible you may need to come in. We recommend that you plan to stay nearby during this time.'
      //   },  
      // },
      // {
      //   id: 'greyout-3',
      //   title: 'Stay Close (Recommended)',
      //   start: recoveryStart.clone().add(3, 'days').format('YYYY-MM-DD'), // after 3 days of recovery, grey out the rest of recovery
      //   end: recoveryStart.clone().add(7, 'days').format('YYYY-MM-DD'),
      //   allDay:true,
      //   className : ["greyout"],
      //   textColor: 'white',
      //   display:'strip',
      //   borderColor: 'black',
      //   extendedProps:{
      //     description:'These dates are not typically clinic visit dates but it is possible you may need to come in. We recommend that you plan to stay nearby during this time.'
      //   },  
      // },

      {
        id: 'call-day-1',
        title: 'Call in your Day 1!',
        extendedProps:{
          icon:
          <FontAwesomeIcon icon={faPhone} size="md"></FontAwesomeIcon>,
          description: ` <span> When you're ready to begin treatment, report your day 1 by calling into the nursing line at <a href="tel:+1-416-595-0077">416-595-0077</a>.</span>`,
          type: 'icon-button'
        },
        start: firstDayOfCycle.clone().format('YYYY-MM-DD'),
        allDay:true,
        className : ["call-day-1"],
        textColor: 'black',
        display:'auto',
        borderColor: 'black',
      },
      {
        id: 'no-medications-1',
        title: `No Fertility Medications`,
        extendedProps:{
        },
        start: firstDayOfCycle.clone().format('YYYY-MM-DD'),
        end: firstDayOfCycle.clone().add(noMeds1Duration,'days').format('YYYY-MM-DD'),
        allDay:true,
        className : ["no-medications"],
        textColor: 'black',
        display:'background',
        borderColor: 'black',
      },
      {
        id: 'phase-1-meds',
        title: 'Phase 1: Pre-treatment',
        /* eventContent can be specified to inject custom html into the event. This kills the default styling though, which is a con */
        extendedProps:{
          icon:<><FontAwesomeIcon icon={faPills} size="lg"></FontAwesomeIcon></>,
          description:
            `<ul> 
              <li>During this phase, you will be instructed to take pre-treatment medications. Specifically, vaginal estrogen (called Lupin- Estradiol), and later adding in vaginal progesterone (called Prometrium or Endometrin). </li>
              <li>These medications help coordinate the growth of the follicles, to support a stronger response to ovarian stimulation medications, and to assist with scheduling the cycle.</li>
            </ul>`,
        },
        start: preTreatmentStart.format('YYYY-MM-DD'),
        end: preTreatmentEnd.clone().add(1, 'days').format('YYYY-MM-DD'),  //fullcalendar end dates are exclusive, so pad by 1 https://fullcalendar.io/docs/event-object
        allDay:true,
        className : ["phase-1-meds"],
        borderColor: 'blue',
        display:'background',
        overlap: false,
        textColor: 'black',
      },
      {
        id: 'no-medications-2',
        title: 'No Fertility Medications',
        start: preTreatmentEnd.clone().add(1, 'days').format('YYYY-MM-DD'),
        end: preTreatmentEnd.clone().add(noMeds2Duration,'days').format('YYYY-MM-DD'),
        allDay:true,
        className : ["no-medications"],
        textColor: 'black',
        display:'background',
      },
      {
        id: 'pre-treatment-visit',
        title: 'Clinic Visit 1',
        extendedProps:{
          icon:
          <FontAwesomeIcon icon={faHospital} size="lg"></FontAwesomeIcon>,
          description:
            `<ul> 
              <li>Our monitoring hours are between 7:00am-10:40am Monday through Friday (weekends as needed by appointment only). Your clinical care team will schedule these visits, and they will involve blood work and internal (transvaginal) ultrasound. </li>
              <li>Medications will be reviewed during Cycle Orientation with your nurse.</li>
            </ul>`,
          type: 'icon-button'  
        },

        start: preTreatmentVisit.clone().format('YYYY-MM-DD'),
        allDay:true,
        className : ["clinic-visit"],
      },
      {
        id: 'phase-2-ovarian-stimulation',
        title: 'Phase 2: Ovarian Stimulation',
        extendedProps:{
          icon: <><FontAwesomeIcon icon={faSyringe} size="lg"></FontAwesomeIcon></>,
          description:
            'You will use injectable medications (just under the skin in your belly), along with some oral medications, to stimulate the ovaries to grow follicles. The average length of time for Phase 2 is ten days.' ,
        },
        start: stimulationStart.clone().format('YYYY-MM-DD'),
        end: stimulationEnd.clone().add(1, 'days').format('YYYY-MM-DD'),  //fullcalendar end dates are exclusive, so pad by 1 https://fullcalendar.io/docs/event-object
        allDay:true,
        className : ["phase-2-ovarian-stimulation"],
        display:'background',
        borderColor: 'black',
        textColor: 'black',
      },
      // {
      //   id: 'monitoring-visit-1',
      //   title: 'Clinic Visit 2',
      //   extendedProps:{
      //     icon:
      //     <FontAwesomeIcon icon={faHospital} size="lg"></FontAwesomeIcon>,
      //     description: 'Our monitoring hours are between 7:00am-10:40am Monday through Friday (weekends as needed by appointment only). Your clinical care team will schedule these visits, and they will involve blood work and internal (transvaginal) ultrasound.',
      //     type: 'icon-button'
      //   },
      //   start: stimulationStart.clone().format('YYYY-MM-DD'),
      //   allDay:true,
      //   display:'auto',
      //   className : ["clinic-visit"],
      // },
      // {
      //   id: 'monitoring-visit-2',
      //   title: 'Clinic Visit 3',
      //   extendedProps:{
      //     icon:
      //     <FontAwesomeIcon icon={faHospital} size="lg"></FontAwesomeIcon>,
      //     description: 'Our monitoring hours are between 7:00am-10:40am Monday through Friday (weekends as needed by appointment only). Your clinical care team will schedule these visits, and they will involve blood work and internal (transvaginal) ultrasound.',
      //     type: 'icon-button'
      //   },
      //   start: stimulationStart.clone().add(4, 'days').format('YYYY-MM-DD'),
      //   allDay:true,
      //   display:'auto',
      //   className : ["clinic-visit"],
      // },
      // {
      //   id: 'monitoring-visit-3',
      //   title: 'Clinic Visit 4',
      //   extendedProps:{
      //     icon:
      //     <FontAwesomeIcon icon={faHospital} size="lg"></FontAwesomeIcon>,
      //     description: 'Our monitoring hours are between 7:00am-10:40am Monday through Friday (weekends as needed by appointment only). Your clinical care team will schedule these visits, and they will involve blood work and internal (transvaginal) ultrasound.',
      //     type: 'icon-button'
      //   },
      //   start: stimulationStart.clone().add(7, 'days').format('YYYY-MM-DD'),
      //   allDay:true,
      //   display:'auto',
      //   className : ["clinic-visit"],
      // },
      {
        id: 'phase-3-trigger-retrieval',
        title: 'Phase 3: Trigger and Retrieval Window',
        start: triggerRetrievalStart.clone().format('YYYY-MM-DD'),
        end: triggerRetrievalEnd.clone().add(1, 'days').format('YYYY-MM-DD'),  //fullcalendar end dates are exclusive, so pad by 1 https://fullcalendar.io/docs/event-object
        allDay:true,
        className : ["phase-3-trigger"],
        display:'background',
        borderColor: 'black',
        extendedProps:{
          icon:
            <><FontAwesomeIcon icon={faEgg} size="xl"></FontAwesomeIcon></>,
          description:
            `<ul> 
              <li> In this phase, you will trigger ovulation with injectable medications. The egg retrieval will take place 48 hours later.    </li>
              <li> On egg retrieval day, you will receive sedating medication and will need to take the entire day off work. </li>
            </ul>`,
        },

      },    
      {
        id: 'phase-4-recovery',
        title: 'Phase 4: Recovery',
        extendedProps:{
          // icon: <><FontAwesomeIcon icon={faSnowflake} size="lg"></FontAwesomeIcon></>,
          description:
          `<ul> 
            <li> The day following your egg retrieval, you will hear from the IVF Lab. If you are proceeding with IVF, you will hear from the Lab again about one week later.     </li>
            <li> During the recovery period we want to ensure you remain in Toronto. </li>
          </ul>`,
        },
        start: recoveryStart.format('YYYY-MM-DD'),
        end: recoveryEnd.add(1, 'days').format('YYYY-MM-DD'),  //fullcalendar end dates are exclusive, so pad by 1 https://fullcalendar.io/docs/event-object
        allDay:true,
        className : ["phase-4-recovery"],
        display:'background',
        borderColor: 'black',
      },
      ]

      if (calendarMode==='clinical'){
        // add cycle review event
        // if (!isEmpty(cycleReviewDatetime?.date)){
          events.push ({
            id: `cycle-review-datetime`,
            title: `Cycle Orientation`,
            extendedProps:{
              icon:
              <FontAwesomeIcon icon={faVideo} size="md"></FontAwesomeIcon>,
              description: `Cycle Orientation${!isEmpty(cycleReviewDatetime?.time) ?', '+ moment(cycleReviewDatetime?.time).clone().format('h:mm a') : ''}`,
              type: 'background'
            },
            start: moment(cycleReviewDatetime?.date).clone().format('YYYY-MM-DD h:mm a'),
            allDay:true,
            className : ["cycle-review"],
            textColor: 'black',
            display:'none',
            borderColor: 'black',
          });
        // }


        // add clinic monitoring appt date times and nurse call events
        const defaultMonitoringVisits = getDefaultMonitoringAppointments(events, stimStartDayAndOffset);

        Object.entries(defaultMonitoringVisits).forEach(([key, dateTime]) => {
          if (!isEmpty(dateTime.date)) {
            // Add monitoring visit
            events.push({
              id: `${key}`,
              title: `Monitoring Visit`,
              extendedProps: {
                icon: <FontAwesomeIcon icon={faHospital} size="md"></FontAwesomeIcon>,
                description: `Monitoring Appt${!isEmpty(dateTime.time) ? ', ' + moment(dateTime.time).clone().format('h:mm a') : ''}`,
                time: dateTime.time,
                type: 'background'
              },
              start: moment(dateTime.date).clone().format('YYYY-MM-DD h:mm a'),
              allDay: true,
              className: ["monitoring-visit"],
              textColor: 'black',
              display: 'auto',
              borderColor: 'black'
            });
        
            // Add nurse call event, as they mirror monitoring appointments
            events.push({
              id: `nurse-call-${key}`,
              title: 'Call from nurse',
              extendedProps: {
                icon: <FontAwesomeIcon icon={faPhone} size="md"></FontAwesomeIcon>,
                description: 'Call from nurse',
                type: 'icon-button'
              },
              start: moment(dateTime.date).clone().format('YYYY-MM-DD'),
              allDay: true,
              className: ["nurse-call"],
              textColor: 'black',
              display: 'auto',
              borderColor: 'black'
            });
          }
        });
        
        // Add clinic visit for trigger visits to d10,11,12
        const estimatedTriggerVisits = {
          d10: { date: getDateAfterDays(stimulationStart, 10-1), id: 'd10', description: `Monitoring Appt - Trigger (TBD)`, icon: true },
          d11: { date: getDateAfterDays(stimulationStart, 11-1), id: 'd11', description: `Monitoring Appt TBD`, icon: true },
          d12: { date: getDateAfterDays(stimulationStart, 12-1), id: 'd12', description: `Egg Retrieval (TBD)`, icon: false }
        };
        
        Object.entries(estimatedTriggerVisits).forEach(([key, { date, description, icon }]) => {
          events.push({
            id: `clinic-visit-trigger-${key}`,
            title: `Trigger Visit`,
            extendedProps: {
              icon: icon ? <FontAwesomeIcon icon={faHospital} size="md"></FontAwesomeIcon> : '',
              description: <span dangerouslySetInnerHTML={{ __html: description }}></span>,
              type: 'background'
            },
            start: moment(date).format('YYYY-MM-DD h:mm a'),
            allDay: true,
            className: ["trigger-visit"],
            textColor: 'black',
            display: 'auto',
            borderColor: 'black'
          });
        
          // Add nurse call event for d10 monitoring appointments

          if (key === 'd10') {
            events.push({
              id: `nurse-call-${key}`,
              title: 'Call from nurse',
              extendedProps: {
                icon: <FontAwesomeIcon icon={faPhone} size="md"></FontAwesomeIcon>,
                description: 'Call from nurse',
                type: 'icon-button'
              },
              start: moment(date).clone().format('YYYY-MM-DD'),
              allDay: true,
              className: ["nurse-call"],
              textColor: 'black',
              display: 'auto',
              borderColor: 'black'
            });
          }
        });
      } else if (calendarMode === 'patient'){
        const patientMonitoringVisits = getDefaultMonitoringAppointments(events, stimStartDayAndOffset);
        const visitsToShow = ['clinic-visit-monitoring-1', 'clinic-visit-monitoring-2', 'clinic-visit-monitoring-3'];
        const patientMonitoringVisitsToShow = visitsToShow.map(key => patientMonitoringVisits[key]);

        Object.entries(patientMonitoringVisitsToShow).forEach(([key, visit], idx) => {
            events.unshift({
              id: key,
              title: `Clinic Visit ${idx+2}`,//+2 to account for 0 start, and the first clinic visit for pretreatment
              extendedProps: {
                icon: <FontAwesomeIcon icon={faHospital} size="lg" />,
                description: 'Our monitoring hours are between 7:00am-10:40am Monday through Friday (weekends as needed by appointment only). Your clinical care team will schedule these visits, and they will involve blood work and internal (transvaginal) ultrasound.',
                type: 'icon-button'
              },
              start: moment(visit.date).format('YYYY-MM-DD'),  // Assuming visit has a date property
              allDay: true,
              display: 'auto',
              className: ["clinic-visit"]
            });
        });
        const [visit1, visit2, visit3] = patientMonitoringVisitsToShow;
        // Generate stay-close and greyout events
        const stayCloseEvents = [];
        const greyoutEvents = [];

        // stay-close events
        stayCloseEvents.push(
          {
              id: 'stay-close-1',
              title: 'Stay Close (Required)',
              start: moment(visit1.date).format('YYYY-MM-DD'),
              allDay: true,
              className: ["blackout"],
              textColor: 'white',
              display: 'strip',
              borderColor: 'black',
              extendedProps: {
                  description: '<b>No Travel.</b> These dates are typically clinic visit dates and you will be required to come in. You must stay nearby during this time.'
              },
          },
          {
              id: 'stay-close-2',
              title: 'Stay Close (Required)',
              start: moment(visit2.date).format('YYYY-MM-DD'),
              allDay: true,
              className: ["blackout"],
              textColor: 'white',
              display: 'strip',
              borderColor: 'black',
              extendedProps: {
                  description: '<b>No Travel.</b> These dates are typically clinic visit dates and you will be required to come in. You must stay nearby during this time.'
              },
          },
          {
              id: 'stay-close-3',
              title: 'Stay Close (Required)',
              start: moment(visit3.date).format('YYYY-MM-DD'),
              end: triggerRetrievalEnd.clone().add(4, 'days').format('YYYY-MM-DD'), // Pad by 1 day as fullcalendar end dates are exclusive
              allDay: true,
              className: ["blackout"],
              textColor: 'white',
              display: 'strip',
              borderColor: 'black',
              extendedProps: {
                  description: '<b>No Travel.</b> These dates are typically clinic visit dates and you will be required to come in. You must stay nearby during this time.'
              },
          }
      );

      // greyout events
      greyoutEvents.push(
          {
              id: 'greyout-1',
              title: 'Stay Close (Recommended)',
              start: moment(visit1.date).add(1, 'days').format('YYYY-MM-DD'),
              end: moment(visit2.date).format('YYYY-MM-DD'),  // Exclusive of end date
              allDay: true,
              className: ["greyout"],
              textColor: 'white',
              display: 'strip',
              borderColor: 'black',
              extendedProps: {
                  description: 'These dates are not typically clinic visit dates but it is possible you may need to come in. We recommend that you plan to stay nearby during this time.'
              },
          },
          {
              id: 'greyout-2',
              title: 'Stay Close (Recommended)',
              start: moment(visit2.date).add(1, 'days').format('YYYY-MM-DD'),
              end: moment(visit3.date).format('YYYY-MM-DD'),  // Exclusive of end date
              allDay: true,
              className: ["greyout"],
              textColor: 'white',
              display: 'strip',
              borderColor: 'black',
              extendedProps: {
                  description: 'These dates are not typically clinic visit dates but it is possible you may need to come in. We recommend that you plan to stay nearby during this time.'
              },
          },
          {
              id: 'greyout-3',
              title: 'Stay Close (Recommended)',
              start: recoveryStart.clone().add(3, 'days').format('YYYY-MM-DD'), // after 3 days of recovery, grey out the rest of recovery
              end: recoveryStart.clone().add(7, 'days').format('YYYY-MM-DD'),
              allDay: true,
              className: ["greyout"],
              textColor: 'white',
              display: 'strip',
              borderColor: 'black',
              extendedProps: {
                  description: 'These dates are not typically clinic visit dates but it is possible you may need to come in. We recommend that you plan to stay nearby during this time.'
              },
          }
      );

      // Unshift new events to the beginning of the events array
      stayCloseEvents.forEach(event => events.unshift(event));
      greyoutEvents.forEach(event => events.unshift(event));

        

        
      }
            
  // console.log(events)
  return {events, conflictingDateRanges};
}
export function generateClinicalCalendarEvents(events, amh, weight, takingAntibiotics, pergoverisDose) {
  
  const clinicalCalendarEvents = [];
  const clinicVisitEvents = events.filter(event => event.id.startsWith('clinic-visit'));
  const nurseCallEvents = events.filter(event => event.id.includes('nurse-call'));
  const cycleReviewEvent = events.filter(event => event.id.includes('cycle-review-datetime'));

  events.forEach((event, idx) => {
      if (event.id.includes('phase') || event.id === 'no-medications-2' ) {
          const phaseId = event.id;
          const phaseStartDate = moment(event.start);
          const phaseEndDate = moment(event.end);
          const duration = phaseEndDate.diff(phaseStartDate, 'days'); // Excluding the end date
          
          for (let i = 0; i < duration; i++) { // Update the loop condition to exclude the last day
              const dayEvent = {
                  id: `${phaseId}-day-${i+1}`,
                  // title: event.title,
                  start: phaseStartDate.clone().add(i, 'days').format('YYYY-MM-DD'),
                  allDay: true,
                  className: event.className,
                  textColor: event.textColor,
                  display: event.display,
                  borderColor: event.borderColor,
                  extendedProps: {
                      icon: event.extendedProps?.icon, // Include icon data,
                      order: i+1,
                      phaseId
                  }
              };
              
              const matchingClinicVisit = clinicVisitEvents.filter(visit => moment(dayEvent.start).isSame(visit.start, 'day'))[0];
              const matchingNurseCall = nurseCallEvents.filter(call => moment(dayEvent.start).isSame(call.start, 'day'))[0];
              const matchingCycleReview = cycleReviewEvent.find(visit => moment(dayEvent.start).isSame(visit.start, 'day'));

              if (event.id === 'phase-1-meds' && i === 0){
                dayEvent.extendedProps.extraInfo = <span className='mark'>For more detailed instructions, please see page 3</span>;
              }
              if (event.id.includes('no-medications')){
                dayEvent.extendedProps.extraInfo = 'No Fertility Medications';
              }

              if (!isEmpty(matchingClinicVisit)){
                dayEvent.extendedProps.clinicVisit = matchingClinicVisit;
              }
              if (!isEmpty(matchingNurseCall)){
                dayEvent.extendedProps.nurseCall = matchingNurseCall;
              }
              if (!isEmpty(matchingCycleReview)){
                dayEvent.extendedProps.cycleReview = matchingCycleReview;
              }
              // append medications to event
              dayEvent.extendedProps = {...dayEvent.extendedProps, ...getDayNightMeds(event, dayEvent, amh, weight, takingAntibiotics, pergoverisDose)};

              clinicalCalendarEvents.push(dayEvent);
          }
      }
  });
  return clinicalCalendarEvents;
}

function getDayNightMeds(event, dayOfEvent, amh, weight, takingAntibiotics, pergoverisDose){
  const momentStartDate = moment(event.start);
  const momentEndDate = moment(event.end);
  const dayOfEventMoment = moment(dayOfEvent);

  switch (event.id) {
    case 'phase-1-meds':
      return getPhase1Meds(event, dayOfEvent, amh, weight, momentStartDate, momentEndDate, dayOfEventMoment);
    case 'phase-2-ovarian-stimulation':
      return getPhase2Meds(event, dayOfEvent, amh, weight, momentStartDate, momentEndDate, dayOfEventMoment, pergoverisDose);
    case 'phase-3-trigger-retrieval':
      return getPhase3Meds(event, dayOfEvent, amh, weight, momentStartDate, momentEndDate, dayOfEventMoment, takingAntibiotics, pergoverisDose);
    case 'phase-4-recovery':
      return getPhase4Meds(event, dayOfEvent, amh, weight, momentStartDate, momentEndDate, dayOfEventMoment, takingAntibiotics);
    default:
  }
}


// attempt to generalize event edits, without the complexity of the bulk edit functionality in the patient calendar
// editing monitoring appointments is set up here to replace start date/time based on form selection. 
// return value can be saved as the new event list
export const updateCurrentEventList = (events, eventsToUpdate) => {
  const eventListClone = [...events];
  Object.keys(eventsToUpdate).forEach(key => {
    const eventListIndex = getArrayIdFromObject(eventListClone, 'id', key)
    eventListClone.findIndex(event => event.id === key);
    const dateTime = eventsToUpdate[key];

    // Find and update clinical visit monitoring event
    if (key.startsWith('clinic-visit-monitoring')) {
      if (eventListIndex !== -1) {
        eventListClone[eventListIndex].start = moment(dateTime.date).clone().format('YYYY-MM-DD');
        eventListClone[eventListIndex].extendedProps.description = `Monitoring Appt${!isEmpty(dateTime.time) ? ', ' + moment(dateTime.time).clone().format('h:mm a') : ''}`;
        eventListClone[eventListIndex].extendedProps.time = dateTime.time;
        // Find and update corresponding nurse call event
        const nurseCallEventIndex = getArrayIdFromObject(eventListClone, 'id', `nurse-call-${key}`)

        if (nurseCallEventIndex !== -1) {
          eventListClone[nurseCallEventIndex].start = moment(dateTime.date).clone().format('YYYY-MM-DD');
        }
      }
    } else if (key === 'cycle-review-datetime'){
      eventListClone[eventListIndex].start = moment(dateTime.date).clone().format('YYYY-MM-DD');
      eventListClone[eventListIndex].extendedProps.description = `Cycle Orientation${!isEmpty(dateTime?.time) ?', '+ moment(dateTime?.time).clone().format('h:mm a') : ''}`;
      eventListClone[eventListIndex].extendedProps.time = dateTime.time;
      eventListClone[eventListIndex].display = 'auto';
  }

    // General update for any other events
    if (eventListIndex !== -1) {
      eventListClone[eventListIndex].start = moment(dateTime.date).clone().format('YYYY-MM-DD h:mm a');
    }
  });
  return eventListClone;
};

