const pergoverisLookupString = 
`Weight(kg),AMH,Dosage
43,0,275
43,1,275
43,2,275
43,3,275
43,4,275
43,5,275
43,6,300
43,7,300
43,8,300
43,9,300
43,10,300
43,11,300
43,12,300
43,13,275
43,14,250
43,15,225
43,16,225
43,17,200
43,18,200
43,19,175
43,20,175
43,21,150
43,22,150
43,23,150
43,24,150
43,25,150
43,26,150
43,27,150
43,28,150
43,29,150
43,30,150
43,31,150
43,32,150
43,33,150
43,34,150
43,35,150
43,36,150
43,37,150
43,38,150
43,39,150
43,40,150
44,0,275
44,1,275
44,2,275
44,3,275
44,4,275
44,5,275
44,6,300
44,7,300
44,8,300
44,9,300
44,10,300
44,11,300
44,12,300
44,13,275
44,14,250
44,15,225
44,16,225
44,17,200
44,18,200
44,19,200
44,20,200
44,21,175
44,22,175
44,23,150
44,24,150
44,25,150
44,26,150
44,27,150
44,28,150
44,29,150
44,30,150
44,31,150
44,32,150
44,33,150
44,34,150
44,35,150
44,36,150
44,37,150
44,38,150
44,39,150
44,40,150
45,0,275
45,1,275
45,2,275
45,3,275
45,4,275
45,5,275
45,6,300
45,7,300
45,8,300
45,9,300
45,10,300
45,11,300
45,12,300
45,13,275
45,14,250
45,15,225
45,16,225
45,17,200
45,18,200
45,19,200
45,20,200
45,21,175
45,22,175
45,23,150
45,24,150
45,25,150
45,26,150
45,27,150
45,28,150
45,29,150
45,30,150
45,31,150
45,32,150
45,33,150
45,34,150
45,35,150
45,36,150
45,37,150
45,38,150
45,39,150
45,40,150
46,0,275
46,1,275
46,2,275
46,3,275
46,4,275
46,5,275
46,6,300
46,7,300
46,8,300
46,9,300
46,10,300
46,11,300
46,12,300
46,13,275
46,14,250
46,15,225
46,16,225
46,17,225
46,18,200
46,19,200
46,20,200
46,21,200
46,22,200
46,23,150
46,24,150
46,25,150
46,26,150
46,27,150
46,28,150
46,29,150
46,30,150
46,31,150
46,32,150
46,33,150
46,34,150
46,35,150
46,36,150
46,37,150
46,38,150
46,39,150
46,40,150
47,0,275
47,1,275
47,2,275
47,3,275
47,4,275
47,5,275
47,6,300
47,7,300
47,8,300
47,9,300
47,10,300
47,11,300
47,12,300
47,13,275
47,14,250
47,15,225
47,16,225
47,17,225
47,18,200
47,19,200
47,20,200
47,21,200
47,22,200
47,23,175
47,24,175
47,25,150
47,26,150
47,27,150
47,28,150
47,29,150
47,30,150
47,31,150
47,32,150
47,33,150
47,34,150
47,35,150
47,36,150
47,37,150
47,38,150
47,39,150
47,40,150
48,0,275
48,1,275
48,2,275
48,3,275
48,4,275
48,5,275
48,6,300
48,7,300
48,8,300
48,9,300
48,10,300
48,11,300
48,12,300
48,13,300
48,14,275
48,15,225
48,16,225
48,17,225
48,18,200
48,19,200
48,20,200
48,21,200
48,22,200
48,23,175
48,24,175
48,25,150
48,26,150
48,27,150
48,28,150
48,29,150
48,30,150
48,31,150
48,32,150
48,33,150
48,34,150
48,35,150
48,36,150
48,37,150
48,38,150
48,39,150
48,40,150
49,0,275
49,1,275
49,2,275
49,3,275
49,4,275
49,5,275
49,6,300
49,7,300
49,8,300
49,9,300
49,10,300
49,11,300
49,12,300
49,13,300
49,14,275
49,15,225
49,16,225
49,17,225
49,18,225
49,19,200
49,20,200
49,21,200
49,22,200
49,23,200
49,24,200
49,25,150
49,26,150
49,27,150
49,28,150
49,29,150
49,30,150
49,31,150
49,32,150
49,33,150
49,34,150
49,35,150
49,36,150
49,37,150
49,38,150
49,39,150
49,40,150
50,0,275
50,1,275
50,2,275
50,3,275
50,4,275
50,5,275
50,6,325
50,7,325
50,8,325
50,9,325
50,10,325
50,11,325
50,12,325
50,13,300
50,14,275
50,15,250
50,16,250
50,17,225
50,18,225
50,19,200
50,20,200
50,21,200
50,22,200
50,23,200
50,24,200
50,25,150
50,26,150
50,27,150
50,28,150
50,29,150
50,30,150
50,31,150
50,32,150
50,33,150
50,34,150
50,35,150
50,36,150
50,37,150
50,38,150
50,39,150
50,40,150
51,0,275
51,1,275
51,2,275
51,3,275
51,4,275
51,5,275
51,6,325
51,7,325
51,8,325
51,9,325
51,10,325
51,11,325
51,12,325
51,13,300
51,14,275
51,15,250
51,16,250
51,17,225
51,18,225
51,19,200
51,20,200
51,21,200
51,22,200
51,23,200
51,24,200
51,25,175
51,26,175
51,27,175
51,28,150
51,29,150
51,30,150
51,31,150
51,32,150
51,33,150
51,34,150
51,35,150
51,36,150
51,37,150
51,38,150
51,39,150
51,40,150
52,0,275
52,1,275
52,2,275
52,3,275
52,4,275
52,5,275
52,6,325
52,7,325
52,8,325
52,9,325
52,10,325
52,11,325
52,12,325
52,13,300
52,14,275
52,15,250
52,16,250
52,17,225
52,18,225
52,19,225
52,20,225
52,21,200
52,22,200
52,23,200
52,24,200
52,25,175
52,26,175
52,27,175
52,28,150
52,29,150
52,30,150
52,31,150
52,32,150
52,33,150
52,34,150
52,35,150
52,36,150
52,37,150
52,38,150
52,39,150
52,40,150
53,0,275
53,1,275
53,2,275
53,3,275
53,4,275
53,5,275
53,6,350
53,7,350
53,8,350
53,9,350
53,10,350
53,11,350
53,12,350
53,13,325
53,14,300
53,15,250
53,16,250
53,17,250
53,18,225
53,19,225
53,20,225
53,21,200
53,22,200
53,23,200
53,24,200
53,25,200
53,26,200
53,27,200
53,28,150
53,29,150
53,30,150
53,31,150
53,32,150
53,33,150
53,34,150
53,35,150
53,36,150
53,37,150
53,38,150
53,39,150
53,40,150
54,0,275
54,1,275
54,2,275
54,3,275
54,4,275
54,5,275
54,6,350
54,7,350
54,8,350
54,9,350
54,10,350
54,11,350
54,12,350
54,13,325
54,14,300
54,15,250
54,16,250
54,17,250
54,18,225
54,19,225
54,20,225
54,21,200
54,22,200
54,23,200
54,24,200
54,25,200
54,26,200
54,27,200
54,28,150
54,29,150
54,30,150
54,31,150
54,32,150
54,33,150
54,34,150
54,35,150
54,36,150
54,37,150
54,38,150
54,39,150
54,40,150
55,0,275
55,1,275
55,2,275
55,3,275
55,4,275
55,5,275
55,6,350
55,7,350
55,8,350
55,9,350
55,10,350
55,11,350
55,12,350
55,13,325
55,14,300
55,15,250
55,16,250
55,17,250
55,18,225
55,19,225
55,20,225
55,21,225
55,22,225
55,23,200
55,24,200
55,25,200
55,26,200
55,27,200
55,28,175
55,29,175
55,30,175
55,31,175
55,32,175
55,33,175
55,34,150
55,35,150
55,36,150
55,37,150
55,38,150
55,39,150
55,40,150
56,0,275
56,1,275
56,2,275
56,3,275
56,4,275
56,5,275
56,6,350
56,7,350
56,8,350
56,9,350
56,10,350
56,11,350
56,12,350
56,13,325
56,14,300
56,15,275
56,16,275
56,17,250
56,18,250
56,19,225
56,20,225
56,21,225
56,22,225
56,23,200
56,24,200
56,25,200
56,26,200
56,27,200
56,28,175
56,29,175
56,30,175
56,31,175
56,32,175
56,33,175
56,34,150
56,35,150
56,36,150
56,37,150
56,38,150
56,39,150
56,40,150
57,0,275
57,1,275
57,2,275
57,3,275
57,4,275
57,5,275
57,6,350
57,7,350
57,8,350
57,9,350
57,10,350
57,11,350
57,12,350
57,13,325
57,14,300
57,15,275
57,16,275
57,17,250
57,18,250
57,19,225
57,20,225
57,21,225
57,22,225
57,23,200
57,24,200
57,25,200
57,26,200
57,27,200
57,28,200
57,29,200
57,30,200
57,31,200
57,32,200
57,33,175
57,34,175
57,35,150
57,36,150
57,37,150
57,38,150
57,39,150
57,40,150
58,0,275
58,1,275
58,2,275
58,3,275
58,4,275
58,5,275
58,6,350
58,7,350
58,8,350
58,9,350
58,10,350
58,11,350
58,12,350
58,13,350
58,14,325
58,15,275
58,16,275
58,17,250
58,18,250
58,19,225
58,20,225
58,21,225
58,22,225
58,23,200
58,24,200
58,25,200
58,26,200
58,27,200
58,28,200
58,29,200
58,30,200
58,31,200
58,32,200
58,33,175
58,34,175
58,35,150
58,36,150
58,37,150
58,38,150
58,39,150
58,40,150
59,0,300
59,1,300
59,2,300
59,3,300
59,4,300
59,5,300
59,6,350
59,7,350
59,8,350
59,9,350
59,10,350
59,11,350
59,12,350
59,13,350
59,14,325
59,15,275
59,16,275
59,17,275
59,18,250
59,19,225
59,20,225
59,21,225
59,22,225
59,23,225
59,24,225
59,25,200
59,26,200
59,27,200
59,28,200
59,29,200
59,30,200
59,31,200
59,32,200
59,33,175
59,34,175
59,35,150
59,36,150
59,37,150
59,38,150
59,39,150
59,40,150
60,0,300
60,1,300
60,2,300
60,3,300
60,4,300
60,5,300
60,6,350
60,7,350
60,8,350
60,9,350
60,10,350
60,11,350
60,12,350
60,13,350
60,14,325
60,15,275
60,16,275
60,17,275
60,18,250
60,19,250
60,20,250
60,21,225
60,22,225
60,23,225
60,24,225
60,25,200
60,26,200
60,27,200
60,28,200
60,29,200
60,30,200
60,31,200
60,32,200
60,33,175
60,34,175
60,35,175
60,36,175
60,37,175
60,38,175
60,39,175
60,40,150
61,0,300
61,1,300
61,2,300
61,3,300
61,4,300
61,5,300
61,6,350
61,7,350
61,8,350
61,9,350
61,10,350
61,11,350
61,12,350
61,13,350
61,14,325
61,15,275
61,16,275
61,17,275
61,18,250
61,19,250
61,20,250
61,21,225
61,22,225
61,23,225
61,24,225
61,25,200
61,26,200
61,27,200
61,28,200
61,29,200
61,30,200
61,31,200
61,32,200
61,33,175
61,34,175
61,35,175
61,36,175
61,37,175
61,38,175
61,39,175
61,40,150
62,0,300
62,1,300
62,2,300
62,3,300
62,4,300
62,5,300
62,6,350
62,7,350
62,8,350
62,9,350
62,10,350
62,11,350
62,12,350
62,13,350
62,14,325
62,15,275
62,16,275
62,17,275
62,18,275
62,19,250
62,20,250
62,21,225
62,22,225
62,23,225
62,24,225
62,25,200
62,26,200
62,27,200
62,28,200
62,29,200
62,30,200
62,31,200
62,32,200
62,33,175
62,34,175
62,35,175
62,36,175
62,37,175
62,38,175
62,39,175
62,40,150
63,0,300
63,1,300
63,2,300
63,3,300
63,4,300
63,5,300
63,6,350
63,7,350
63,8,350
63,9,350
63,10,350
63,11,350
63,12,350
63,13,350
63,14,350
63,15,300
63,16,300
63,17,275
63,18,275
63,19,250
63,20,250
63,21,225
63,22,225
63,23,225
63,24,225
63,25,225
63,26,225
63,27,225
63,28,200
63,29,200
63,30,200
63,31,200
63,32,200
63,33,200
63,34,200
63,35,200
63,36,200
63,37,200
63,38,200
63,39,200
63,40,150
64,0,300
64,1,300
64,2,300
64,3,300
64,4,300
64,5,300
64,6,350
64,7,350
64,8,350
64,9,350
64,10,350
64,11,350
64,12,350
64,13,350
64,14,350
64,15,300
64,16,300
64,17,275
64,18,275
64,19,250
64,20,250
64,21,250
64,22,250
64,23,225
64,24,225
64,25,225
64,26,225
64,27,225
64,28,200
64,29,200
64,30,200
64,31,200
64,32,200
64,33,200
64,34,200
64,35,200
64,36,200
64,37,200
64,38,200
64,39,200
64,40,150
65,0,325
65,1,325
65,2,325
65,3,325
65,4,325
65,5,325
65,6,350
65,7,350
65,8,350
65,9,350
65,10,350
65,11,350
65,12,350
65,13,350
65,14,350
65,15,300
65,16,300
65,17,275
65,18,275
65,19,250
65,20,250
65,21,250
65,22,250
65,23,225
65,24,225
65,25,225
65,26,225
65,27,225
65,28,200
65,29,200
65,30,200
65,31,200
65,32,200
65,33,200
65,34,200
65,35,200
65,36,200
65,37,200
65,38,200
65,39,200
65,40,150
66,0,325
66,1,325
66,2,325
66,3,325
66,4,325
66,5,325
66,6,350
66,7,350
66,8,350
66,9,350
66,10,350
66,11,350
66,12,350
66,13,350
66,14,350
66,15,300
66,16,300
66,17,300
66,18,275
66,19,275
66,20,275
66,21,250
66,22,250
66,23,225
66,24,225
66,25,225
66,26,225
66,27,225
66,28,200
66,29,200
66,30,200
66,31,200
66,32,200
66,33,200
66,34,200
66,35,200
66,36,200
66,37,200
66,38,200
66,39,200
66,40,175
67,0,325
67,1,325
67,2,325
67,3,325
67,4,325
67,5,325
67,6,350
67,7,350
67,8,350
67,9,350
67,10,350
67,11,350
67,12,350
67,13,350
67,14,350
67,15,300
67,16,300
67,17,300
67,18,275
67,19,275
67,20,275
67,21,250
67,22,250
67,23,225
67,24,225
67,25,225
67,26,225
67,27,225
67,28,200
67,29,200
67,30,200
67,31,200
67,32,200
67,33,200
67,34,200
67,35,200
67,36,200
67,37,200
67,38,200
67,39,200
67,40,175
68,0,325
68,1,325
68,2,325
68,3,325
68,4,325
68,5,325
68,6,350
68,7,350
68,8,350
68,9,350
68,10,350
68,11,350
68,12,350
68,13,350
68,14,350
68,15,325
68,16,325
68,17,300
68,18,275
68,19,275
68,20,275
68,21,250
68,22,250
68,23,250
68,24,250
68,25,225
68,26,225
68,27,225
68,28,200
68,29,200
68,30,200
68,31,200
68,32,200
68,33,200
68,34,200
68,35,200
68,36,200
68,37,200
68,38,200
68,39,200
68,40,175
69,0,325
69,1,325
69,2,325
69,3,325
69,4,325
69,5,325
69,6,350
69,7,350
69,8,350
69,9,350
69,10,350
69,11,350
69,12,350
69,13,350
69,14,350
69,15,325
69,16,325
69,17,300
69,18,275
69,19,275
69,20,275
69,21,250
69,22,250
69,23,250
69,24,250
69,25,225
69,26,225
69,27,225
69,28,225
69,29,225
69,30,225
69,31,225
69,32,225
69,33,200
69,34,200
69,35,200
69,36,200
69,37,200
69,38,200
69,39,200
69,40,200
70,0,325
70,1,325
70,2,325
70,3,325
70,4,325
70,5,325
70,6,350
70,7,350
70,8,350
70,9,350
70,10,350
70,11,350
70,12,350
70,13,350
70,14,350
70,15,325
70,16,325
70,17,300
70,18,300
70,19,275
70,20,275
70,21,275
70,22,275
70,23,250
70,24,250
70,25,225
70,26,225
70,27,225
70,28,225
70,29,225
70,30,225
70,31,225
70,32,225
70,33,200
70,34,200
70,35,200
70,36,200
70,37,200
70,38,200
70,39,200
70,40,200
71,0,350
71,1,350
71,2,350
71,3,350
71,4,350
71,5,350
71,6,350
71,7,350
71,8,350
71,9,350
71,10,350
71,11,350
71,12,350
71,13,350
71,14,350
71,15,325
71,16,325
71,17,325
71,18,300
71,19,275
71,20,275
71,21,275
71,22,275
71,23,250
71,24,250
71,25,225
71,26,225
71,27,225
71,28,225
71,29,225
71,30,225
71,31,225
71,32,225
71,33,200
71,34,200
71,35,200
71,36,200
71,37,200
71,38,200
71,39,200
71,40,200
72,0,350
72,1,350
72,2,350
72,3,350
72,4,350
72,5,350
72,6,350
72,7,350
72,8,350
72,9,350
72,10,350
72,11,350
72,12,350
72,13,350
72,14,350
72,15,325
72,16,325
72,17,325
72,18,300
72,19,275
72,20,275
72,21,275
72,22,275
72,23,250
72,24,250
72,25,225
72,26,225
72,27,225
72,28,225
72,29,225
72,30,225
72,31,225
72,32,225
72,33,200
72,34,200
72,35,200
72,36,200
72,37,200
72,38,200
72,39,200
72,40,200
73,0,350
73,1,350
73,2,350
73,3,350
73,4,350
73,5,350
73,6,350
73,7,350
73,8,350
73,9,350
73,10,350
73,11,350
73,12,350
73,13,350
73,14,350
73,15,350
73,16,350
73,17,325
73,18,300
73,19,275
73,20,275
73,21,275
73,22,275
73,23,250
73,24,250
73,25,225
73,26,225
73,27,225
73,28,225
73,29,225
73,30,225
73,31,225
73,32,225
73,33,200
73,34,200
73,35,200
73,36,200
73,37,200
73,38,200
73,39,200
73,40,200
74,0,350
74,1,350
74,2,350
74,3,350
74,4,350
74,5,350
74,6,350
74,7,350
74,8,350
74,9,350
74,10,350
74,11,350
74,12,350
74,13,350
74,14,350
74,15,350
74,16,350
74,17,325
74,18,300
74,19,300
74,20,300
74,21,275
74,22,275
74,23,250
74,24,250
74,25,250
74,26,250
74,27,250
74,28,225
74,29,225
74,30,225
74,31,225
74,32,225
74,33,200
74,34,200
74,35,200
74,36,200
74,37,200
74,38,200
74,39,200
74,40,200
75,0,350
75,1,350
75,2,350
75,3,350
75,4,350
75,5,350
75,6,350
75,7,350
75,8,350
75,9,350
75,10,350
75,11,350
75,12,350
75,13,350
75,14,350
75,15,350
75,16,350
75,17,325
75,18,325
75,19,300
75,20,300
75,21,275
75,22,275
75,23,275
75,24,275
75,25,250
75,26,250
75,27,250
75,28,225
75,29,225
75,30,225
75,31,225
75,32,225
75,33,225
75,34,225
75,35,225
75,36,225
75,37,225
75,38,225
75,39,225
75,40,200
76,0,350
76,1,350
76,2,350
76,3,350
76,4,350
76,5,350
76,6,350
76,7,350
76,8,350
76,9,350
76,10,350
76,11,350
76,12,350
76,13,350
76,14,350
76,15,350
76,16,350
76,17,350
76,18,325
76,19,300
76,20,300
76,21,275
76,22,275
76,23,275
76,24,275
76,25,250
76,26,250
76,27,250
76,28,225
76,29,225
76,30,225
76,31,225
76,32,225
76,33,225
76,34,225
76,35,225
76,36,225
76,37,225
76,38,225
76,39,225
76,40,200
77,0,350
77,1,350
77,2,350
77,3,350
77,4,350
77,5,350
77,6,350
77,7,350
77,8,350
77,9,350
77,10,350
77,11,350
77,12,350
77,13,350
77,14,350
77,15,350
77,16,350
77,17,350
77,18,325
77,19,300
77,20,300
77,21,275
77,22,275
77,23,275
77,24,275
77,25,250
77,26,250
77,27,250
77,28,225
77,29,225
77,30,225
77,31,225
77,32,225
77,33,225
77,34,225
77,35,225
77,36,225
77,37,225
77,38,225
77,39,225
77,40,200
78,0,350
78,1,350
78,2,350
78,3,350
78,4,350
78,5,350
78,6,350
78,7,350
78,8,350
78,9,350
78,10,350
78,11,350
78,12,350
78,13,350
78,14,350
78,15,350
78,16,350
78,17,350
78,18,325
78,19,300
78,20,300
78,21,275
78,22,275
78,23,275
78,24,275
78,25,250
78,26,250
78,27,250
78,28,225
78,29,225
78,30,225
78,31,225
78,32,225
78,33,225
78,34,225
78,35,225
78,36,225
78,37,225
78,38,225
78,39,225
78,40,200
79,0,350
79,1,350
79,2,350
79,3,350
79,4,350
79,5,350
79,6,350
79,7,350
79,8,350
79,9,350
79,10,350
79,11,350
79,12,350
79,13,350
79,14,350
79,15,350
79,16,350
79,17,350
79,18,325
79,19,325
79,20,325
79,21,300
79,22,300
79,23,275
79,24,275
79,25,250
79,26,250
79,27,250
79,28,225
79,29,225
79,30,225
79,31,225
79,32,225
79,33,225
79,34,225
79,35,225
79,36,225
79,37,225
79,38,225
79,39,225
79,40,200
80,0,350
80,1,350
80,2,350
80,3,350
80,4,350
80,5,350
80,6,350
80,7,350
80,8,350
80,9,350
80,10,350
80,11,350
80,12,350
80,13,350
80,14,350
80,15,350
80,16,350
80,17,350
80,18,350
80,19,325
80,20,325
80,21,300
80,22,300
80,23,275
80,24,275
80,25,250
80,26,250
80,27,250
80,28,250
80,29,250
80,30,250
80,31,250
80,32,250
80,33,225
80,34,225
80,35,225
80,36,225
80,37,225
80,38,225
80,39,225
80,40,200
81,0,350
81,1,350
81,2,350
81,3,350
81,4,350
81,5,350
81,6,350
81,7,350
81,8,350
81,9,350
81,10,350
81,11,350
81,12,350
81,13,350
81,14,350
81,15,350
81,16,350
81,17,350
81,18,350
81,19,325
81,20,325
81,21,300
81,22,300
81,23,275
81,24,275
81,25,275
81,26,275
81,27,275
81,28,250
81,29,250
81,30,250
81,31,250
81,32,250
81,33,225
81,34,225
81,35,225
81,36,225
81,37,225
81,38,225
81,39,225
81,40,200
82,0,350
82,1,350
82,2,350
82,3,350
82,4,350
82,5,350
82,6,350
82,7,350
82,8,350
82,9,350
82,10,350
82,11,350
82,12,350
82,13,350
82,14,350
82,15,350
82,16,350
82,17,350
82,18,350
82,19,325
82,20,325
82,21,300
82,22,300
82,23,275
82,24,275
82,25,275
82,26,275
82,27,275
82,28,250
82,29,250
82,30,250
82,31,250
82,32,250
82,33,225
82,34,225
82,35,225
82,36,225
82,37,225
82,38,225
82,39,225
82,40,225
83,0,350
83,1,350
83,2,350
83,3,350
83,4,350
83,5,350
83,6,350
83,7,350
83,8,350
83,9,350
83,10,350
83,11,350
83,12,350
83,13,350
83,14,350
83,15,350
83,16,350
83,17,350
83,18,350
83,19,325
83,20,325
83,21,300
83,22,300
83,23,275
83,24,275
83,25,275
83,26,275
83,27,275
83,28,250
83,29,250
83,30,250
83,31,250
83,32,250
83,33,225
83,34,225
83,35,225
83,36,225
83,37,225
83,38,225
83,39,225
83,40,225
84,0,350
84,1,350
84,2,350
84,3,350
84,4,350
84,5,350
84,6,350
84,7,350
84,8,350
84,9,350
84,10,350
84,11,350
84,12,350
84,13,350
84,14,350
84,15,350
84,16,350
84,17,350
84,18,350
84,19,350
84,20,350
84,21,325
84,22,325
84,23,275
84,24,275
84,25,275
84,26,275
84,27,275
84,28,250
84,29,250
84,30,250
84,31,250
84,32,250
84,33,225
84,34,225
84,35,225
84,36,225
84,37,225
84,38,225
84,39,225
84,40,225
85,0,350
85,1,350
85,2,350
85,3,350
85,4,350
85,5,350
85,6,350
85,7,350
85,8,350
85,9,350
85,10,350
85,11,350
85,12,350
85,13,350
85,14,350
85,15,350
85,16,350
85,17,350
85,18,350
85,19,350
85,20,350
85,21,325
85,22,325
85,23,300
85,24,300
85,25,275
85,26,275
85,27,275
85,28,250
85,29,250
85,30,250
85,31,250
85,32,250
85,33,225
85,34,225
85,35,225
85,36,225
85,37,225
85,38,225
85,39,225
85,40,225
86,0,450
86,1,450
86,2,450
86,3,450
86,4,450
86,5,450
86,6,450
86,7,450
86,8,450
86,9,450
86,10,450
86,11,450
86,12,450
86,13,450
86,14,450
86,15,350
86,16,350
86,17,350
86,18,350
86,19,350
86,20,350
86,21,325
86,22,325
86,23,300
86,24,300
86,25,275
86,26,275
86,27,275
86,28,250
86,29,250
86,30,250
86,31,250
86,32,250
86,33,225
86,34,225
86,35,225
86,36,225
86,37,225
86,38,225
86,39,225
86,40,225
87,0,450
87,1,450
87,2,450
87,3,450
87,4,450
87,5,450
87,6,450
87,7,450
87,8,450
87,9,450
87,10,450
87,11,450
87,12,450
87,13,450
87,14,450
87,15,350
87,16,350
87,17,350
87,18,350
87,19,350
87,20,350
87,21,325
87,22,325
87,23,300
87,24,300
87,25,275
87,26,275
87,27,275
87,28,250
87,29,250
87,30,250
87,31,250
87,32,250
87,33,250
87,34,250
87,35,250
87,36,250
87,37,250
87,38,250
87,39,250
87,40,225
88,0,450
88,1,450
88,2,450
88,3,450
88,4,450
88,5,450
88,6,450
88,7,450
88,8,450
88,9,450
88,10,450
88,11,450
88,12,450
88,13,450
88,14,450
88,15,350
88,16,350
88,17,350
88,18,350
88,19,350
88,20,350
88,21,325
88,22,325
88,23,300
88,24,300
88,25,275
88,26,275
88,27,275
88,28,275
88,29,275
88,30,275
88,31,275
88,32,275
88,33,250
88,34,250
88,35,250
88,36,250
88,37,250
88,38,250
88,39,250
88,40,225
89,0,450
89,1,450
89,2,450
89,3,450
89,4,450
89,5,450
89,6,450
89,7,450
89,8,450
89,9,450
89,10,450
89,11,450
89,12,450
89,13,450
89,14,450
89,15,350
89,16,350
89,17,350
89,18,350
89,19,350
89,20,350
89,21,350
89,22,350
89,23,300
89,24,300
89,25,275
89,26,275
89,27,275
89,28,275
89,29,275
89,30,275
89,31,275
89,32,275
89,33,250
89,34,250
89,35,250
89,36,250
89,37,250
89,38,250
89,39,250
89,40,225
90,0,450
90,1,450
90,2,450
90,3,450
90,4,450
90,5,450
90,6,450
90,7,450
90,8,450
90,9,450
90,10,450
90,11,450
90,12,450
90,13,450
90,14,450
90,15,350
90,16,350
90,17,350
90,18,350
90,19,350
90,20,350
90,21,350
90,22,350
90,23,325
90,24,325
90,25,275
90,26,275
90,27,275
90,28,275
90,29,275
90,30,275
90,31,275
90,32,275
90,33,250
90,34,250
90,35,250
90,36,250
90,37,250
90,38,250
90,39,250
90,40,225
91,0,450
91,1,450
91,2,450
91,3,450
91,4,450
91,5,450
91,6,450
91,7,450
91,8,450
91,9,450
91,10,450
91,11,450
91,12,450
91,13,450
91,14,450
91,15,350
91,16,350
91,17,350
91,18,350
91,19,350
91,20,350
91,21,350
91,22,350
91,23,325
91,24,325
91,25,275
91,26,275
91,27,275
91,28,275
91,29,275
91,30,275
91,31,275
91,32,275
91,33,250
91,34,250
91,35,250
91,36,250
91,37,250
91,38,250
91,39,250
91,40,225
92,0,450
92,1,450
92,2,450
92,3,450
92,4,450
92,5,450
92,6,450
92,7,450
92,8,450
92,9,450
92,10,450
92,11,450
92,12,450
92,13,450
92,14,450
92,15,350
92,16,350
92,17,350
92,18,350
92,19,350
92,20,350
92,21,350
92,22,350
92,23,325
92,24,325
92,25,300
92,26,300
92,27,300
92,28,275
92,29,275
92,30,275
92,31,275
92,32,275
92,33,250
92,34,250
92,35,250
92,36,250
92,37,250
92,38,250
92,39,250
92,40,225
93,0,450
93,1,450
93,2,450
93,3,450
93,4,450
93,5,450
93,6,450
93,7,450
93,8,450
93,9,450
93,10,450
93,11,450
93,12,450
93,13,450
93,14,450
93,15,350
93,16,350
93,17,350
93,18,350
93,19,350
93,20,350
93,21,350
93,22,350
93,23,325
93,24,325
93,25,300
93,26,300
93,27,300
93,28,275
93,29,275
93,30,275
93,31,275
93,32,275
93,33,250
93,34,250
93,35,250
93,36,250
93,37,250
93,38,250
93,39,250
93,40,225
94,0,450
94,1,450
94,2,450
94,3,450
94,4,450
94,5,450
94,6,450
94,7,450
94,8,450
94,9,450
94,10,450
94,11,450
94,12,450
94,13,450
94,14,450
94,15,350
94,16,350
94,17,350
94,18,350
94,19,350
94,20,350
94,21,350
94,22,350
94,23,325
94,24,325
94,25,300
94,26,300
94,27,300
94,28,275
94,29,275
94,30,275
94,31,275
94,32,275
94,33,250
94,34,250
94,35,250
94,36,250
94,37,250
94,38,250
94,39,250
94,40,225
95,0,450
95,1,450
95,2,450
95,3,450
95,4,450
95,5,450
95,6,450
95,7,450
95,8,450
95,9,450
95,10,450
95,11,450
95,12,450
95,13,450
95,14,450
95,15,350
95,16,350
95,17,350
95,18,350
95,19,350
95,20,350
95,21,350
95,22,350
95,23,350
95,24,350
95,25,300
95,26,300
95,27,300
95,28,275
95,29,275
95,30,275
95,31,275
95,32,275
95,33,250
95,34,250
95,35,250
95,36,250
95,37,250
95,38,250
95,39,250
95,40,250
96,0,450
96,1,450
96,2,450
96,3,450
96,4,450
96,5,450
96,6,450
96,7,450
96,8,450
96,9,450
96,10,450
96,11,450
96,12,450
96,13,450
96,14,450
96,15,350
96,16,350
96,17,350
96,18,350
96,19,350
96,20,350
96,21,350
96,22,350
96,23,350
96,24,350
96,25,300
96,26,300
96,27,300
96,28,275
96,29,275
96,30,275
96,31,275
96,32,275
96,33,275
96,34,275
96,35,275
96,36,275
96,37,275
96,38,275
96,39,275
96,40,250
97,0,450
97,1,450
97,2,450
97,3,450
97,4,450
97,5,450
97,6,450
97,7,450
97,8,450
97,9,450
97,10,450
97,11,450
97,12,450
97,13,450
97,14,450
97,15,350
97,16,350
97,17,350
97,18,350
97,19,350
97,20,350
97,21,350
97,22,350
97,23,350
97,24,350
97,25,325
97,26,325
97,27,325
97,28,275
97,29,275
97,30,275
97,31,275
97,32,275
97,33,275
97,34,275
97,35,275
97,36,275
97,37,275
97,38,275
97,39,275
97,40,250
98,0,450
98,1,450
98,2,450
98,3,450
98,4,450
98,5,450
98,6,450
98,7,450
98,8,450
98,9,450
98,10,450
98,11,450
98,12,450
98,13,450
98,14,450
98,15,350
98,16,350
98,17,350
98,18,350
98,19,350
98,20,350
98,21,350
98,22,350
98,23,350
98,24,350
98,25,325
98,26,325
98,27,325
98,28,275
98,29,275
98,30,275
98,31,275
98,32,275
98,33,275
98,34,275
98,35,275
98,36,275
98,37,275
98,38,275
98,39,275
98,40,250
99,0,450
99,1,450
99,2,450
99,3,450
99,4,450
99,5,450
99,6,450
99,7,450
99,8,450
99,9,450
99,10,450
99,11,450
99,12,450
99,13,450
99,14,450
99,15,350
99,16,350
99,17,350
99,18,350
99,19,350
99,20,350
99,21,350
99,22,350
99,23,350
99,24,350
99,25,325
99,26,325
99,27,325
99,28,300
99,29,300
99,30,300
99,31,300
99,32,300
99,33,275
99,34,275
99,35,275
99,36,275
99,37,275
99,38,275
99,39,275
99,40,250
100,0,450
100,1,450
100,2,450
100,3,450
100,4,450
100,5,450
100,6,450
100,7,450
100,8,450
100,9,450
100,10,450
100,11,450
100,12,450
100,13,450
100,14,450
100,15,350
100,16,350
100,17,350
100,18,350
100,19,350
100,20,350
100,21,350
100,22,350
100,23,350
100,24,350
100,25,325
100,26,325
100,27,325
100,28,300
100,29,300
100,30,300
100,31,300
100,32,300
100,33,275
100,34,275
100,35,275
100,36,275
100,37,275
100,38,275
100,39,275
100,40,250
101,0,450
101,1,450
101,2,450
101,3,450
101,4,450
101,5,450
101,6,450
101,7,450
101,8,450
101,9,450
101,10,450
101,11,450
101,12,450
101,13,450
101,14,450
101,15,350
101,16,350
101,17,350
101,18,350
101,19,350
101,20,350
101,21,350
101,22,350
101,23,350
101,24,350
101,25,325
101,26,325
101,27,325
101,28,300
101,29,300
101,30,300
101,31,300
101,32,300
101,33,275
101,34,275
101,35,275
101,36,275
101,37,275
101,38,275
101,39,275
101,40,250
102,0,450
102,1,450
102,2,450
102,3,450
102,4,450
102,5,450
102,6,450
102,7,450
102,8,450
102,9,450
102,10,450
102,11,450
102,12,450
102,13,450
102,14,450
102,15,350
102,16,350
102,17,350
102,18,350
102,19,350
102,20,350
102,21,350
102,22,350
102,23,350
102,24,350
102,25,350
102,26,350
102,27,350
102,28,300
102,29,300
102,30,300
102,31,300
102,32,300
102,33,275
102,34,275
102,35,275
102,36,275
102,37,275
102,38,275
102,39,275
102,40,250
103,0,450
103,1,450
103,2,450
103,3,450
103,4,450
103,5,450
103,6,450
103,7,450
103,8,450
103,9,450
103,10,450
103,11,450
103,12,450
103,13,450
103,14,450
103,15,350
103,16,350
103,17,350
103,18,350
103,19,350
103,20,350
103,21,350
103,22,350
103,23,350
103,24,350
103,25,350
103,26,350
103,27,350
103,28,300
103,29,300
103,30,300
103,31,300
103,32,300
103,33,275
103,34,275
103,35,275
103,36,275
103,37,275
103,38,275
103,39,275
103,40,250
104,0,450
104,1,450
104,2,450
104,3,450
104,4,450
104,5,450
104,6,450
104,7,450
104,8,450
104,9,450
104,10,450
104,11,450
104,12,450
104,13,450
104,14,450
104,15,350
104,16,350
104,17,350
104,18,350
104,19,350
104,20,350
104,21,350
104,22,350
104,23,350
104,24,350
104,25,350
104,26,350
104,27,350
104,28,325
104,29,325
104,30,325
104,31,325
104,32,325
104,33,275
104,34,275
104,35,275
104,36,275
104,37,275
104,38,275
104,39,275
104,40,250
105,0,450
105,1,450
105,2,450
105,3,450
105,4,450
105,5,450
105,6,450
105,7,450
105,8,450
105,9,450
105,10,450
105,11,450
105,12,450
105,13,450
105,14,450
105,15,350
105,16,350
105,17,350
105,18,350
105,19,350
105,20,350
105,21,350
105,22,350
105,23,350
105,24,350
105,25,350
105,26,350
105,27,350
105,28,325
105,29,325
105,30,325
105,31,325
105,32,325
105,33,275
105,34,275
105,35,275
105,36,275
105,37,275
105,38,275
105,39,275
105,40,275
106,0,450
106,1,450
106,2,450
106,3,450
106,4,450
106,5,450
106,6,450
106,7,450
106,8,450
106,9,450
106,10,450
106,11,450
106,12,450
106,13,450
106,14,450
106,15,350
106,16,350
106,17,350
106,18,350
106,19,350
106,20,350
106,21,350
106,22,350
106,23,350
106,24,350
106,25,350
106,26,350
106,27,350
106,28,325
106,29,325
106,30,325
106,31,325
106,32,325
106,33,275
106,34,275
106,35,275
106,36,275
106,37,275
106,38,275
106,39,275
106,40,275
107,0,450
107,1,450
107,2,450
107,3,450
107,4,450
107,5,450
107,6,450
107,7,450
107,8,450
107,9,450
107,10,450
107,11,450
107,12,450
107,13,450
107,14,450
107,15,350
107,16,350
107,17,350
107,18,350
107,19,350
107,20,350
107,21,350
107,22,350
107,23,350
107,24,350
107,25,350
107,26,350
107,27,350
107,28,325
107,29,325
107,30,325
107,31,325
107,32,325
107,33,275
107,34,275
107,35,275
107,36,275
107,37,275
107,38,275
107,39,275
107,40,275
108,0,450
108,1,450
108,2,450
108,3,450
108,4,450
108,5,450
108,6,450
108,7,450
108,8,450
108,9,450
108,10,450
108,11,450
108,12,450
108,13,450
108,14,450
108,15,350
108,16,350
108,17,350
108,18,350
108,19,350
108,20,350
108,21,350
108,22,350
108,23,350
108,24,350
108,25,350
108,26,350
108,27,350
108,28,325
108,29,325
108,30,325
108,31,325
108,32,325
108,33,300
108,34,300
108,35,300
108,36,300
108,37,300
108,38,300
108,39,300
108,40,275
109,0,450
109,1,450
109,2,450
109,3,450
109,4,450
109,5,450
109,6,450
109,7,450
109,8,450
109,9,450
109,10,450
109,11,450
109,12,450
109,13,450
109,14,450
109,15,350
109,16,350
109,17,350
109,18,350
109,19,350
109,20,350
109,21,350
109,22,350
109,23,350
109,24,350
109,25,350
109,26,350
109,27,350
109,28,350
109,29,350
109,30,350
109,31,350
109,32,350
109,33,300
109,34,300
109,35,300
109,36,300
109,37,300
109,38,300
109,39,300
109,40,275
110,0,450
110,1,450
110,2,450
110,3,450
110,4,450
110,5,450
110,6,450
110,7,450
110,8,450
110,9,450
110,10,450
110,11,450
110,12,450
110,13,450
110,14,450
110,15,350
110,16,350
110,17,350
110,18,350
110,19,350
110,20,350
110,21,350
110,22,350
110,23,350
110,24,350
110,25,350
110,26,350
110,27,350
110,28,350
110,29,350
110,30,350
110,31,350
110,32,350
110,33,300
110,34,300
110,35,300
110,36,300
110,37,300
110,38,300
110,39,300
110,40,275
111,0,450
111,1,450
111,2,450
111,3,450
111,4,450
111,5,450
111,6,450
111,7,450
111,8,450
111,9,450
111,10,450
111,11,450
111,12,450
111,13,450
111,14,450
111,15,350
111,16,350
111,17,350
111,18,350
111,19,350
111,20,350
111,21,350
111,22,350
111,23,350
111,24,350
111,25,350
111,26,350
111,27,350
111,28,350
111,29,350
111,30,350
111,31,350
111,32,350
111,33,300
111,34,300
111,35,300
111,36,300
111,37,300
111,38,300
111,39,300
111,40,300
112,0,450
112,1,450
112,2,450
112,3,450
112,4,450
112,5,450
112,6,450
112,7,450
112,8,450
112,9,450
112,10,450
112,11,450
112,12,450
112,13,450
112,14,450
112,15,350
112,16,350
112,17,350
112,18,350
112,19,350
112,20,350
112,21,350
112,22,350
112,23,350
112,24,350
112,25,350
112,26,350
112,27,350
112,28,350
112,29,350
112,30,350
112,31,350
112,32,350
112,33,300
112,34,300
112,35,300
112,36,300
112,37,300
112,38,300
112,39,300
112,40,300
113,0,450
113,1,450
113,2,450
113,3,450
113,4,450
113,5,450
113,6,450
113,7,450
113,8,450
113,9,450
113,10,450
113,11,450
113,12,450
113,13,450
113,14,450
113,15,350
113,16,350
113,17,350
113,18,350
113,19,350
113,20,350
113,21,350
113,22,350
113,23,350
113,24,350
113,25,350
113,26,350
113,27,350
113,28,350
113,29,350
113,30,350
113,31,350
113,32,350
113,33,325
113,34,325
113,35,325
113,36,325
113,37,325
113,38,325
113,39,325
113,40,300
114,0,450
114,1,450
114,2,450
114,3,450
114,4,450
114,5,450
114,6,450
114,7,450
114,8,450
114,9,450
114,10,450
114,11,450
114,12,450
114,13,450
114,14,450
114,15,350
114,16,350
114,17,350
114,18,350
114,19,350
114,20,350
114,21,350
114,22,350
114,23,350
114,24,350
114,25,350
114,26,350
114,27,350
114,28,350
114,29,350
114,30,350
114,31,350
114,32,350
114,33,325
114,34,325
114,35,325
114,36,325
114,37,325
114,38,325
114,39,325
114,40,300
115,0,450
115,1,450
115,2,450
115,3,450
115,4,450
115,5,450
115,6,450
115,7,450
115,8,450
115,9,450
115,10,450
115,11,450
115,12,450
115,13,450
115,14,450
115,15,350
115,16,350
115,17,350
115,18,350
115,19,350
115,20,350
115,21,350
115,22,350
115,23,350
115,24,350
115,25,350
115,26,350
115,27,350
115,28,350
115,29,350
115,30,350
115,31,350
115,32,350
115,33,325
115,34,325
115,35,325
115,36,325
115,37,325
115,38,325
115,39,325
115,40,300
116,0,450
116,1,450
116,2,450
116,3,450
116,4,450
116,5,450
116,6,450
116,7,450
116,8,450
116,9,450
116,10,450
116,11,450
116,12,450
116,13,450
116,14,450
116,15,350
116,16,350
116,17,350
116,18,350
116,19,350
116,20,350
116,21,350
116,22,350
116,23,350
116,24,350
116,25,350
116,26,350
116,27,350
116,28,350
116,29,350
116,30,350
116,31,350
116,32,350
116,33,325
116,34,325
116,35,325
116,36,325
116,37,325
116,38,325
116,39,325
116,40,325
117,0,450
117,1,450
117,2,450
117,3,450
117,4,450
117,5,450
117,6,450
117,7,450
117,8,450
117,9,450
117,10,450
117,11,450
117,12,450
117,13,450
117,14,450
117,15,350
117,16,350
117,17,350
117,18,350
117,19,350
117,20,350
117,21,350
117,22,350
117,23,350
117,24,350
117,25,350
117,26,350
117,27,350
117,28,350
117,29,350
117,30,350
117,31,350
117,32,350
117,33,325
117,34,325
117,35,325
117,36,325
117,37,325
117,38,325
117,39,325
117,40,325
118,0,450
118,1,450
118,2,450
118,3,450
118,4,450
118,5,450
118,6,450
118,7,450
118,8,450
118,9,450
118,10,450
118,11,450
118,12,450
118,13,450
118,14,450
118,15,350
118,16,350
118,17,350
118,18,350
118,19,350
118,20,350
118,21,350
118,22,350
118,23,350
118,24,350
118,25,350
118,26,350
118,27,350
118,28,350
118,29,350
118,30,350
118,31,350
118,32,350
118,33,350
118,34,350
118,35,350
118,36,350
118,37,350
118,38,350
118,39,350
118,40,325
119,0,450
119,1,450
119,2,450
119,3,450
119,4,450
119,5,450
119,6,450
119,7,450
119,8,450
119,9,450
119,10,450
119,11,450
119,12,450
119,13,450
119,14,450
119,15,350
119,16,350
119,17,350
119,18,350
119,19,350
119,20,350
119,21,350
119,22,350
119,23,350
119,24,350
119,25,350
119,26,350
119,27,350
119,28,350
119,29,350
119,30,350
119,31,350
119,32,350
119,33,350
119,34,350
119,35,350
119,36,350
119,37,350
119,38,350
119,39,350
119,40,325
120,0,450
120,1,450
120,2,450
120,3,450
120,4,450
120,5,450
120,6,450
120,7,450
120,8,450
120,9,450
120,10,450
120,11,450
120,12,450
120,13,450
120,14,450
120,15,350
120,16,350
120,17,350
120,18,350
120,19,350
120,20,350
120,21,350
120,22,350
120,23,350
120,24,350
120,25,350
120,26,350
120,27,350
120,28,350
120,29,350
120,30,350
120,31,350
120,32,350
120,33,350
120,34,350
120,35,350
120,36,350
120,37,350
120,38,350
120,39,350
120,40,325
121,0,450
121,1,450
121,2,450
121,3,450
121,4,450
121,5,450
121,6,450
121,7,450
121,8,450
121,9,450
121,10,450
121,11,450
121,12,450
121,13,450
121,14,450
121,15,350
121,16,350
121,17,350
121,18,350
121,19,350
121,20,350
121,21,350
121,22,350
121,23,350
121,24,350
121,25,350
121,26,350
121,27,350
121,28,350
121,29,350
121,30,350
121,31,350
121,32,350
121,33,350
121,34,350
121,35,350
121,36,350
121,37,350
121,38,350
121,39,350
121,40,350
122,0,450
122,1,450
122,2,450
122,3,450
122,4,450
122,5,450
122,6,450
122,7,450
122,8,450
122,9,450
122,10,450
122,11,450
122,12,450
122,13,450
122,14,450
122,15,350
122,16,350
122,17,350
122,18,350
122,19,350
122,20,350
122,21,350
122,22,350
122,23,350
122,24,350
122,25,350
122,26,350
122,27,350
122,28,350
122,29,350
122,30,350
122,31,350
122,32,350
122,33,350
122,34,350
122,35,350
122,36,350
122,37,350
122,38,350
122,39,350
122,40,350
123,0,450
123,1,450
123,2,450
123,3,450
123,4,450
123,5,450
123,6,450
123,7,450
123,8,450
123,9,450
123,10,450
123,11,450
123,12,450
123,13,450
123,14,450
123,15,350
123,16,350
123,17,350
123,18,350
123,19,350
123,20,350
123,21,350
123,22,350
123,23,350
123,24,350
123,25,350
123,26,350
123,27,350
123,28,350
123,29,350
123,30,350
123,31,350
123,32,350
123,33,350
123,34,350
123,35,350
123,36,350
123,37,350
123,38,350
123,39,350
123,40,350
124,0,450
124,1,450
124,2,450
124,3,450
124,4,450
124,5,450
124,6,450
124,7,450
124,8,450
124,9,450
124,10,450
124,11,450
124,12,450
124,13,450
124,14,450
124,15,350
124,16,350
124,17,350
124,18,350
124,19,350
124,20,350
124,21,350
124,22,350
124,23,350
124,24,350
124,25,350
124,26,350
124,27,350
124,28,350
124,29,350
124,30,350
124,31,350
124,32,350
124,33,350
124,34,350
124,35,350
124,36,350
124,37,350
124,38,350
124,39,350
124,40,350
125,0,450
125,1,450
125,2,450
125,3,450
125,4,450
125,5,450
125,6,450
125,7,450
125,8,450
125,9,450
125,10,450
125,11,450
125,12,450
125,13,450
125,14,450
125,15,350
125,16,350
125,17,350
125,18,350
125,19,350
125,20,350
125,21,350
125,22,350
125,23,350
125,24,350
125,25,350
125,26,350
125,27,350
125,28,350
125,29,350
125,30,350
125,31,350
125,32,350
125,33,350
125,34,350
125,35,350
125,36,350
125,37,350
125,38,350
125,39,350
125,40,350
126,0,450
126,1,450
126,2,450
126,3,450
126,4,450
126,5,450
126,6,450
126,7,450
126,8,450
126,9,450
126,10,450
126,11,450
126,12,450
126,13,450
126,14,450
126,15,350
126,16,350
126,17,350
126,18,350
126,19,350
126,20,350
126,21,350
126,22,350
126,23,350
126,24,350
126,25,350
126,26,350
126,27,350
126,28,350
126,29,350
126,30,350
126,31,350
126,32,350
126,33,350
126,34,350
126,35,350
126,36,350
126,37,350
126,38,350
126,39,350
126,40,350
127,0,450
127,1,450
127,2,450
127,3,450
127,4,450
127,5,450
127,6,450
127,7,450
127,8,450
127,9,450
127,10,450
127,11,450
127,12,450
127,13,450
127,14,450
127,15,350
127,16,350
127,17,350
127,18,350
127,19,350
127,20,350
127,21,350
127,22,350
127,23,350
127,24,350
127,25,350
127,26,350
127,27,350
127,28,350
127,29,350
127,30,350
127,31,350
127,32,350
127,33,350
127,34,350
127,35,350
127,36,350
127,37,350
127,38,350
127,39,350
127,40,350
128,0,450
128,1,450
128,2,450
128,3,450
128,4,450
128,5,450
128,6,450
128,7,450
128,8,450
128,9,450
128,10,450
128,11,450
128,12,450
128,13,450
128,14,450
128,15,350
128,16,350
128,17,350
128,18,350
128,19,350
128,20,350
128,21,350
128,22,350
128,23,350
128,24,350
128,25,350
128,26,350
128,27,350
128,28,350
128,29,350
128,30,350
128,31,350
128,32,350
128,33,350
128,34,350
128,35,350
128,36,350
128,37,350
128,38,350
128,39,350
128,40,350
129,0,450
129,1,450
129,2,450
129,3,450
129,4,450
129,5,450
129,6,450
129,7,450
129,8,450
129,9,450
129,10,450
129,11,450
129,12,450
129,13,450
129,14,450
129,15,350
129,16,350
129,17,350
129,18,350
129,19,350
129,20,350
129,21,350
129,22,350
129,23,350
129,24,350
129,25,350
129,26,350
129,27,350
129,28,350
129,29,350
129,30,350
129,31,350
129,32,350
129,33,350
129,34,350
129,35,350
129,36,350
129,37,350
129,38,350
129,39,350
129,40,350
130,0,450
130,1,450
130,2,450
130,3,450
130,4,450
130,5,450
130,6,450
130,7,450
130,8,450
130,9,450
130,10,450
130,11,450
130,12,450
130,13,450
130,14,450
130,15,350
130,16,350
130,17,350
130,18,350
130,19,350
130,20,350
130,21,350
130,22,350
130,23,350
130,24,350
130,25,350
130,26,350
130,27,350
130,28,350
130,29,350
130,30,350
130,31,350
130,32,350
130,33,350
130,34,350
130,35,350
130,36,350
130,37,350
130,38,350
130,39,350
130,40,350
131,0,450
131,1,450
131,2,450
131,3,450
131,4,450
131,5,450
131,6,450
131,7,450
131,8,450
131,9,450
131,10,450
131,11,450
131,12,450
131,13,450
131,14,450
131,15,350
131,16,350
131,17,350
131,18,350
131,19,350
131,20,350
131,21,350
131,22,350
131,23,350
131,24,350
131,25,350
131,26,350
131,27,350
131,28,350
131,29,350
131,30,350
131,31,350
131,32,350
131,33,350
131,34,350
131,35,350
131,36,350
131,37,350
131,38,350
131,39,350
131,40,350
132,0,450
132,1,450
132,2,450
132,3,450
132,4,450
132,5,450
132,6,450
132,7,450
132,8,450
132,9,450
132,10,450
132,11,450
132,12,450
132,13,450
132,14,450
132,15,350
132,16,350
132,17,350
132,18,350
132,19,350
132,20,350
132,21,350
132,22,350
132,23,350
132,24,350
132,25,350
132,26,350
132,27,350
132,28,350
132,29,350
132,30,350
132,31,350
132,32,350
132,33,350
132,34,350
132,35,350
132,36,350
132,37,350
132,38,350
132,39,350
132,40,350
133,0,450
133,1,450
133,2,450
133,3,450
133,4,450
133,5,450
133,6,450
133,7,450
133,8,450
133,9,450
133,10,450
133,11,450
133,12,450
133,13,450
133,14,450
133,15,350
133,16,350
133,17,350
133,18,350
133,19,350
133,20,350
133,21,350
133,22,350
133,23,350
133,24,350
133,25,350
133,26,350
133,27,350
133,28,350
133,29,350
133,30,350
133,31,350
133,32,350
133,33,350
133,34,350
133,35,350
133,36,350
133,37,350
133,38,350
133,39,350
133,40,350
134,0,450
134,1,450
134,2,450
134,3,450
134,4,450
134,5,450
134,6,450
134,7,450
134,8,450
134,9,450
134,10,450
134,11,450
134,12,450
134,13,450
134,14,450
134,15,350
134,16,350
134,17,350
134,18,350
134,19,350
134,20,350
134,21,350
134,22,350
134,23,350
134,24,350
134,25,350
134,26,350
134,27,350
134,28,350
134,29,350
134,30,350
134,31,350
134,32,350
134,33,350
134,34,350
134,35,350
134,36,350
134,37,350
134,38,350
134,39,350
134,40,350
135,0,450
135,1,450
135,2,450
135,3,450
135,4,450
135,5,450
135,6,450
135,7,450
135,8,450
135,9,450
135,10,450
135,11,450
135,12,450
135,13,450
135,14,450
135,15,350
135,16,350
135,17,350
135,18,350
135,19,350
135,20,350
135,21,350
135,22,350
135,23,350
135,24,350
135,25,350
135,26,350
135,27,350
135,28,350
135,29,350
135,30,350
135,31,350
135,32,350
135,33,350
135,34,350
135,35,350
135,36,350
135,37,350
135,38,350
135,39,350
135,40,350
`

export default pergoverisLookupString;