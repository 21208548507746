import React, { useState, useEffect   } from 'react';

import { Dna } from 'react-loader-spinner';
import FullCalendar from '@fullcalendar/react';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import dayGridPlugin from '@fullcalendar/daygrid';

import moment from 'moment/moment';
import eventGenerator from '../../js/eventGenerator';
import EventContent from './EventContent';
import { getArrayIdFromObject, getWeeksBetweenDates, areDatesEqual } from '../../js/common';
import { exportToPDF } from '../../js/exportToPdf';
import LoadingSpinner from '../common/loadingSpinner';
import { isEmpty } from '../../js/common';
import { useMediaQuery } from 'react-responsive';

const TeamIVFCalendar = ({events, startDate, setStartDate, patientBlackouts = [], setShowEditorModal, adminMode, setCalendarKey, calendarKey, innerRef, patientInfo: {name = 'Patient'}}) => {
  
  useEffect(() => {
  if (!isEmpty(innerRef.current)) {
    innerRef.current.scrollIntoView({ behavior: "smooth"});    
  }  
}, [calendarKey]);


  const [isLoading, setIsLoading] = useState(false); // move to context api
  const [printView, setPrintView] = useState(false); 
  const [isEditable] = useState(adminMode);

  const printWidth = printView ? {width: 961} : undefined;
  const isDesktop = useMediaQuery({ query: `(min-width: 961px)` }, printWidth);

  const formattedStart = events[getArrayIdFromObject(events, 'id', "call-day-1")]['start']; // set get the first blackout event to indicate the start of the calendar
  const formattedEnd = events.slice(-1)[0]['end']; // set final event's "end" to be the calendar's official end date
  const weeksBetweenStartEnd = getWeeksBetweenDates(formattedStart, formattedEnd);

  const titleStartDate = moment(formattedStart).format('MMM D, YYYY');
  const titleEndDate = moment(formattedEnd).format('MMM D, YYYY');



  
  function dayRender(eventInfo){
    const dateString = moment(eventInfo.date, 'YYYY-MM-DD');
    const isDay1 = dateString.format('YYYY-MM-DD') === events[getArrayIdFromObject(events, 'id', 'call-day-1')]['start'];

    if(isDesktop && 
      (eventInfo.dow === 0 || eventInfo.isMonthStart || isDay1) )
      {
     return <span className='month-day-shown'>{dateString.format('MMM DD')}</span>
    } else {
      return <span>{dateString.format('DD')}</span>
    }
   }
   
  function onExportPDF(){
    setIsLoading(true);
    exportToPDF("team-ivf-calendar", '.seven-week-calendar-container', setCalendarKey, setIsLoading, setPrintView);
  }


  
  return (
    <>
      {isLoading && <LoadingSpinner/>}
      <div className="seven-week-calendar-container patient-calendar" ref={innerRef}>
      <h1 className='calendar-title'> <span className='text-capitalize'>{!isEmpty(name) ? name : 'Patient'}</span>'s </h1>
      <h1 className='calendar-title'> {`Anticipated Treatment Calendar`}</h1>
        <div className='date-title-container'> 
          <div className='date-title'> 
            <div className='your-dates'>Your Dates:</div><div className='date-string'>{titleStartDate} – {titleEndDate}</div> 
          </div>
        </div>
        <div className='btn-container'>
          { isEditable === true && 
            <button className='btn btn-primary' disabled={isEmpty(events)} onClick={() => setShowEditorModal(true)}>Edit Events</button>
          }
          <button className='btn btn-primary' onClick={onExportPDF}>Export Calendar PDF</button>
        </div>
        <FullCalendar
          dayCellContent ={dayRender}
          expandRows={true}    
          key={calendarKey} // informs the calendar to regenerate if start/end dates change
          themeSystem='bootstrap5'
          plugins={
            [dayGridPlugin, bootstrap5Plugin]}
          initialView="dayGridSevenWeek" // Use dayGridMonth as the initial view
          views= {{
            dayGridSevenWeek: {
              type: 'dayGrid',
              duration: { weeks: weeksBetweenStartEnd + 1 }} // pad by 1 in case final or first week aren't full treatment weeks
          }}
          monthStartFormat={{month: 'short', day:'numeric' }}
          validRange={{
            start: formattedStart,
            end: formattedEnd
          }}    
          headerToolbar={{
            left:   '',
            center: '',
            right:  ''
          }}
          // contentHeight='200px'
          height='auto'
          initialDate={formattedStart}
          aspectRatio={1.15}
          // events={events}
          events={events}
          eventContent={(info) => <EventContent innerProps={info} printView={printView}></EventContent>}
          // windowResize={() => {
          //   // console.log('resize!!!!!!!!!!')
          //   // setKey(key+1);

          // }}
        />
      </div>
    </>
    );
}

export default TeamIVFCalendar;




