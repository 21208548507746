import React from 'react';

const MedicationList = ({ key, meds, icon}) => (
  <div className='med-list-container'>
    {meds.map((medItem, index) => (
      <span key={key+index}>{icon} <span className={medItem.medicationClassName}>{medItem.med} {medItem.dosage}</span><br /> </span>
    ))}
  </div>
);

export default MedicationList;
