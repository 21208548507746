import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import Form from 'react-bootstrap/Form';
import moment from 'moment';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { isEmpty } from '../../js/common';


const EditEventInput = ({event, onEventEdit, withDescription=true, viewOnly=false}) => {
    const {id, start, end, extendedProps} = event;

    const [startDate, setStartDate] = useState(start ? new Date(start): undefined);
    const [endDate, setEndDate] = useState(end ? new Date(end): undefined);
    const [editedDescription, setEditedDescription] = useState(extendedProps?.description);
    
    const onChangeDate = (dates) => {
        if (Array.isArray(dates)){
            // Multi-Date Selector
            const [newStart, newEnd] = dates;

            setStartDate(newStart);
            setEndDate(newEnd);

            // only save to EventEditor if both start+end have been added
            if (!isEmpty(newStart) && !isEmpty(newEnd)){
                const updatedEventInfo = {
                    description:editedDescription,
                    start: moment(newStart).format('YYYY-MM-DD'),
                    end: moment(newEnd).clone().add(1, 'days').format('YYYY-MM-DD') // revert end dates date exclusiveness padding for fullcalendar to accurately read date
                };
                onEventEdit(id, updatedEventInfo);
            }

        } else {
            // Single Date Selector
            const newStart = dates;
            setStartDate(newStart);
            const updatedEventInfo = {description:editedDescription, start: moment(newStart).format('YYYY-MM-DD')}
            onEventEdit(id, updatedEventInfo);
        }

      };
      
      const handleDescriptionChange = (value, oldValue, source) => {
        
        if (source === 'user' ){ // react quill seems to fire onChange a ton internally - filter only user changes here before saving something! 
            setEditedDescription(value);
            const updatedEventInfo = {description: value, start: moment(startDate).format('YYYY-MM-DD')}
            if (!isEmpty(endDate)){
                updatedEventInfo.end = moment(endDate).clone().add(1, 'days').format('YYYY-MM-DD'); // revert end dates date exclusiveness padding for fullcalendar to accurately read date
            }
            onEventEdit(id, updatedEventInfo);
        }

      };

        return (
        <Form className={`edit-${id}`}>
            <Form.Group className="event-date-editor w-100" controlId="formTravelling">
                {/* <Form.Label className="travel-dates-label">Please specify any date ranges where you will not be able to visit the clinic. <br/> <i>You may select multiple date ranges:</i> </Form.Label> */}
                
                {start && end ? 
                <DatePicker
                    placeholderText="Select Date Range"
                    selected={startDate}
                    onChange={onChangeDate}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    disabled={viewOnly}
                /> :   
                <DatePicker
                    placeholderText="Select Date Range"
                    selected={startDate}
                    onChange={onChangeDate}
                    startDate={startDate}
                    disabled={viewOnly}
                />
            }
                {withDescription && 
                <div className='description-editor-container'>
                    <ReactQuill
                        theme="snow"
                        value={editedDescription}
                        onChange={handleDescriptionChange}
                    />
                </div>
            }

            </Form.Group>
        </Form>
    );
  };
  export default EditEventInput;
