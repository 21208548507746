import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import Form from 'react-bootstrap/Form';
import EditEventInput from './EditEventInput';
import moment from 'moment';
import { getArrayIdFromObject, isEmpty, cascadeEventList } from '../../js/common';


const CascadeForm = ({editedEvent, originalEvents, onCascadeConfirm, alertRef, daysToCascade}) => {
    const [toggleCascade, setToggleCascade] = useState(undefined);
    const [cascadedEvents, setCascadedEvents] = useState([]);
    
      
    const onEventEdit = () => {
    // Not meaningful to edit events in the cascade form without seeing the calendar.
    // I'd like to learn more about needs first. 
    }

    
    return (
        <Form className='cascade-event-form-container'>
          <Form.Label> Would you like to push subsequent events {daysToCascade} day(s) to the future?</Form.Label>
          <div className='inline-radio'>
                <Form.Check
                    checked={toggleCascade===true}
                    type="radio"
                    label="Yes"
                    value={true}
                    name="radioGroup" 
                    id="radioOption1"
                    onChange={() => {
                        setToggleCascade(true);
                        const cascadedEvents = cascadeEventList(originalEvents, editedEvent);
                        setCascadedEvents(cascadedEvents);
                    }}
                />
                <Form.Check
                    checked={toggleCascade===false}
                    type="radio"
                    label="No"
                    value={false}
                    name="radioGroup" 
                    id="radioOption2"
                    onChange={() => {
                        setToggleCascade(false);
                        setCascadedEvents([])
                    }}
                />
            </div>
            <br/>
            <button 
                className='btn btn-primary' 
                disabled={isEmpty(toggleCascade)}
                onClick={(e)=>{ 
                    e.preventDefault()
                    onCascadeConfirm([...cascadedEvents, editedEvent]); // the original editedEvent is always submitted, regardless of user selection of cascading or not
                    if (toggleCascade === true){
                      alertRef.current.fire({
                        title: 'Success!',
                        text: `The events have been pushed back ${daysToCascade} day(s). You will see them reflected in the form, and can confirm your changes there.`,
                        icon: 'success',
                        showConfirmButton: true, // Optional: hide the confirmation button
                      });
                    } else {
                    alertRef.current?.close();
                  }
                }}
            > Confirm
            </button><br/><br/>

            { toggleCascade && 
            <>
                <Form.Label> <b>Preview of events pushed forward {daysToCascade} day(s):</b> </Form.Label>
                {
                cascadedEvents.map((editedEvent) => {
                  return <div>
                    <div className={`edit-event ${editedEvent.className}`}>{editedEvent.title || editedEvent.id}</div>
                    <EditEventInput
                      key={new Date()}
                      event={editedEvent}
                      onEventEdit={onEventEdit}
                      withDescription={false}
                      viewOnly={true}
                  />
                  <br/>
                  </div>
                })
              }
            </>
            }
        </Form>
    );
  };
  export default CascadeForm;
