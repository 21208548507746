import React, { useState, useEffect} from 'react';

import { isEmpty } from '../../js/common';
import {PatientDay1Input, PatientDay1Wrapper} from './PatientDay1Input';
import { faHouseChimneyMedical, faBan} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommonModal from '../common/CommonModal';
import moment from 'moment';


const BlackoutModal = ({calendarConfig, calendarKey, setCalendarKey, onClose, conflictingDateRanges, onFormSubmit }) => {
    const [name] = useState(calendarConfig.patientInfo['name'])
    const [startDate, setStartDate] = useState(calendarConfig.startDate);

    
    const BlackoutModalBody = 
        <div>
            {!isEmpty(name) && name!== 'Patient' && <p className='text-center text-capitalize'>Hi {name}, </p>}
            <ul>
                <li> Your selected Day 1 of <b>{moment(calendarConfig.startDate).clone().format('MMM D, YYYY')}</b> overlaps with one of your commitments.  <b>If there are conflicts, please speak with a member of your team for guidance.</b></li> 
            </ul>

            {conflictingDateRanges && 
            <React.Fragment>
            <p className='text-center'>
                <b>Conflicting Dates:</b>
                {conflictingDateRanges.map(({range, type}) => {
                    const typeOfBlackout = type === 'clinic' ? 'Clinic Closure' : 'My Committment';
                    const icon = type === 'clinic' ? <FontAwesomeIcon icon={faHouseChimneyMedical} size="xl"/> : <FontAwesomeIcon icon={faBan} size="xl"/>;
                    return(                
                    <div>{icon}&nbsp;<b>{typeOfBlackout}</b>: {range.start.format('MMM D, YYYY')} - {range.end.format('MMM D, YYYY')}</div>
                    )
                }
                )}
            </p>
            </React.Fragment>
            }


            <p className='text-center'>To get a new set of dates, please enter your next expected Day 1:</p>
            <PatientDay1Input setStartDate={setStartDate} startDate={startDate} setCalendarKey={setCalendarKey}></PatientDay1Input>
            <div className='btn-center-container'>
                <button className='btn btn-primary' onClick={() => onFormSubmit({...calendarConfig, startDate})} >Submit</button>
            </div>
        </div>

    return(
        <CommonModal
            onClose={onClose}
            title='Proposing a new Day 1'
            body={BlackoutModalBody}
        />
    )
}

export default BlackoutModal;


